import { Guid } from "guid-typescript";
import { getFileExtensionMeta } from "./fileUtils";
import { isValidZip } from "./zipUtils";

export default class Utility {
  getStyle = (el, styleProp) => {
    let y, x;
    if (typeof el === "string") {
      x = document.querySelector(el);
    } else {
      x = el;
    }

    if (window.getComputedStyle(el) && document && document.defaultView) {
      y = document.defaultView.getComputedStyle(x, null).getPropertyValue(styleProp);
    } else if (x.currentStyle) {
      y = x.currentStyle[styleProp];
    }

    if (!Number.isInteger(y)) {
      y = parseInt(y);
    }

    return y;
  };

  getFileExtensionMeta = getFileExtensionMeta;
}

export function createGuid(): Guid {
  return Guid.create();
}

type Constructor<T> = { new (...args: any[]): T };
export function OfType<T>(tbd: any, className: Constructor<T>, propertyName?: string): tbd is T {
  if (Array.isArray(tbd)) {
    return tbd.some((x) => {
      if (propertyName && typeof x === "object" && x.hasOwnProperty(propertyName)) {
        return true;
      } else {
        return x instanceof className;
      }
    });
  }
  if (propertyName) {
    return typeof tbd === "object" && tbd.hasOwnProperty(propertyName);
  }
  return tbd instanceof className;
}

export const nameofFactory =
  <T>() =>
  (name: keyof T) =>
    name;

export const IsValidZip = (zip: string): boolean => isValidZip(zip);

export function ExpirationYears(maxYears: number = 10): Array<number> {
  let max = maxYears;
  const thisYear: number = new Date().getFullYear();
  const years: Array<number> = [];
  while (max > -1) {
    years.push(thisYear + max);
    max--;
  }
  return years.sort((a, b) => a - b);
}

/**
 * Removes everything in an array after the index of value
 * This is supposed to be faster than .splice()
 * https://stackoverflow.com/questions/26568536/remove-all-items-after-an-index/26568611
 * @param arr
 * @param indexOf
 * @returns
 */
export function ArrayRemoveAfter<T>(arr: Array<T>, indexOf: T): Array<T> {
  const v = arr.indexOf(indexOf);
  if (v > -1 && arr.length >= v) {
    arr.length = v;
  }
  return arr;
}

export function IsValidObject(obj: any): boolean {
  return obj && typeof obj !== "undefined" && obj !== null && Object.keys(obj).length > 0;
}

export function ArrayDistinct(array) {
  var a = array.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }
  return a;
}
/**
 *
 * @param data
 * @param filename
 * @param mimeType
 */
export function forceBrowserDownload(data: any, filename: string, mimeType: string = "text/html") {
  var element = document.createElement("a");
  element.setAttribute("href", `data:${mimeType};charset=utf-8,${encodeURIComponent(data)}`);
  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
