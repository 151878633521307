import { PageTemplateType } from "../appEnum/PageTemplateType";
import { CompassPoint } from "./geoUtils";
import { isInt } from "./numberUtils";

export const getClosestParentElem = (
  $elem: HTMLElement | any,
  parentSelector: string
): null | HTMLElement => {
  for (; $elem && $elem !== document; $elem = $elem.parentNode) {
    if ($elem.matches(parentSelector)) {
      return $elem;
    }
  }
  return null;
};

export const scrollTop = (pad: number = 50) => window.scrollTo({ top: pad, behavior: "smooth" });
export const scrollToLocation = (id: string) =>
  document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });

//I'm never gonna remember matchMedia (a DOM API) nor the media selector for a mouse
export const hasMouseSupport = (): boolean => matchMedia("(pointer:fine)").matches;

export const hasScriptLoaded = (src: string) =>
  document.querySelector(`script[src="${src}"]`) ? true : false;

export const hasLinkLoaded = (src: string) => (document.querySelector(`link[href="${src}"]`) ? true : false);
export const loadScript = (
  src: string,
  charset: string = "",
  async: boolean = true,
  defer: boolean = false
) => {
  const scriptElement = document.querySelector(`script[src="${src}"]`);

  //Script has been loaded, do nothing
  if (scriptElement) {
    return;
  }

  const script = document.createElement("script");
  script.src = src;
  script.charset = charset;
  script.async = async;
  script.defer = async ? false : defer;

  script.addEventListener("load", () => {
    console.log(`Loaded ${src}`);
  });
  document.body.appendChild(script);
};

export const loadStyle = (href: string, rel: string = "stylesheet", type: string = "text/css") => {
  const linkElement = document.querySelector(`link[href="${href}"]`);

  //Style has been loaded, do nothing
  if (linkElement) {
    return;
  }

  const link = document.createElement("link");
  link.href = href;
  link.rel = rel;
  link.type = type;

  link.addEventListener("load", () => {
    console.log(`Loaded ${href}`);
  });
  document.head.appendChild(link);
};

export const decodeHtml = (encodedHtml: string) => {
  var parser = new DOMParser();
  var decodedHtml = parser.parseFromString(encodedHtml, "text/html").body.textContent;
  return decodedHtml;
};
export const encodeHtml = (decodedHtml: string) => {
  const encoder = document.createElement("div");
  encoder.textContent = decodedHtml;
  return encoder.innerHTML;
};

export const setPageTitle = (pageTemplateTypeId: number, pageTitle: String) => {
  if (pageTemplateTypeId === PageTemplateType.S1StandardMasterTemplate) {
    if (document.title.trim() === "Shipley One |>") {
      document.title = "Shipley One | =>";
      setTimeout(() => {
        document.title = "Shipley One | ===>";
        setTimeout(() => {
          document.title = "Shipley One | =====>";
          setTimeout(() => {
            document.title = "Shipley One | =======>";
            setTimeout(() => {
              document.title = `Shipley One${pageTitle ? " | " : ""}${pageTitle}`;
            }, 75);
          }, 150);
        }, 200);
      }, 75);
    } else {
      document.title = `Shipley One${pageTitle ? " | " : ""}${pageTitle}`;
    }
  } else {
    document.title = (pageTitle ?? "").toString();
  }
};

const getZIndexRelativeToBody = ($elem: HTMLElement | null): string => {
  let $el: HTMLElement | Document | ParentNode | null = $elem;
  let zIndexVal = "";
  for (; $el && $el !== document; $el = $el.parentNode) {
    const val =
      document.defaultView
        ?.getComputedStyle($el as unknown as Element, null)
        .getPropertyValue("z-index")
        ?.trim() ?? "";
    if (val !== "" && val != "auto") {
      zIndexVal = val;
    }
  }

  return zIndexVal;
};

const positionToCompassPointRelationship = (
  position: "top" | "right" | "left" | "bottom" | "auto"
): { parent: CompassPoint; child: CompassPoint } => {
  switch (position) {
    case "top":
      return { parent: "N", child: "S" };
    case "bottom":
      return { parent: "S", child: "N" };
    case "left":
      return { parent: "W", child: "E" };
    case "right":
      return { parent: "E", child: "W" };
    default:
      return { parent: "N", child: "S" };
  }
};

const calculatePosition = (
  $parentRect: DOMRect,
  $popViewPortRect: DOMRect,
  position: "top" | "right" | "left" | "bottom" | "auto" = "auto"
): "top" | "right" | "bottom" | "left" => {
  if (position !== "auto") {
    return position;
  }

  //this might not be 100% right:
  const leftSpace = $parentRect.left - $popViewPortRect.width;
  const topSpace = $parentRect.top - $popViewPortRect.height;
  const rightSpace = window.innerWidth - $parentRect.right + $popViewPortRect.width;
  const bottomSpace = window.innerHeight - $parentRect.bottom + $popViewPortRect.height;

  const maxSpace = Math.max(leftSpace, topSpace, rightSpace, bottomSpace);
  if (maxSpace === topSpace) {
    return "top";
  } else if (maxSpace === leftSpace) {
    return "left";
  } else if (maxSpace == rightSpace) {
    return "right";
  } else {
    return "bottom";
  }
};

const getAnchorPointXy = (
  box: DOMRect,
  compassPoint: CompassPoint,
  windowXOffset,
  windowYOffset
): { x: number; y: number } => {
  switch (compassPoint) {
    case "N":
      return { y: box.y + windowYOffset, x: box.x + box.width / 2 + windowXOffset };
    case "S":
      return { y: box.bottom + windowYOffset, x: box.x + box.width / 2 + windowXOffset };
    case "E":
      return { y: box.y + box.height / 2 + windowYOffset, x: box.x + box.width + windowXOffset };
    case "W":
      return { y: box.y + box.height / 2 + windowYOffset, x: box.x + windowXOffset };
    case "NW":
      return { y: box.y + windowYOffset, x: box.x + windowXOffset };
    case "NE":
      return { y: box.y + windowYOffset, x: box.x + box.width + windowXOffset };
    case "SW":
      return { y: box.bottom + windowYOffset, x: box.x + windowXOffset };
    case "SE":
      return { y: box.bottom + windowYOffset, x: box.x + box.width + windowXOffset };
  }
};

export const recalculateTeleportedElementPosition = (
  $anchorElement: HTMLElement,
  $teleportedElement: HTMLElement,
  position: { parent: CompassPoint; child: CompassPoint } | "top" | "right" | "left" | "bottom" | "auto",
  isCustomerWeb: boolean = false,
  offset: { x: number; y: number } = { x: 0, y: 0 }
): IDomPosition => {
  const anchorBox = $anchorElement?.getBoundingClientRect();
  const linkedBox = $teleportedElement?.getBoundingClientRect();
  const zIndex = getZIndexRelativeToBody($anchorElement);
  const rtn: IDomPosition = { top: "", left: "", zIndex: "" };
  if (anchorBox && linkedBox) {
    const windowXOffset = window.pageXOffset;
    const windowYOffset = window.pageYOffset;
    if (zIndex && isInt(zIndex) && parseInt(zIndex) > -99999) {
      rtn.zIndex = (parseInt(zIndex) + 10).toString();
    }

    let compassRelation: { parent: CompassPoint; child: CompassPoint };

    if (typeof position === "string") {
      const autoPosition = calculatePosition(anchorBox, linkedBox, position);
      rtn.autoSelectedPosition = autoPosition;
      compassRelation = positionToCompassPointRelationship(autoPosition);
    } else {
      compassRelation = position;
    }
    const parentPoint = getAnchorPointXy(anchorBox, compassRelation.parent, windowXOffset, windowYOffset);
    switch (compassRelation.child) {
      case "N":
        rtn.top = `${parentPoint.y + offset.y}px`;
        rtn.left = `${parentPoint.x + offset.x - linkedBox.width / 2}px`;
        break;
      case "S":
        rtn.top = `${parentPoint.y + offset.y - linkedBox.height}px`;
        rtn.left = `${parentPoint.x + offset.x - linkedBox.width / 2}px`;
        break;
      case "E":
        rtn.top = `${parentPoint.y + offset.y - linkedBox.height / 2}px`;
        rtn.left = `${parentPoint.x + offset.x - linkedBox.width}px`;
        break;
      case "W":
        rtn.top = `${parentPoint.y + offset.y - linkedBox.height / 2}px`;
        rtn.left = `${parentPoint.x + offset.x}px`;
        break;
      case "NW":
        rtn.top = `${parentPoint.y + offset.y}px`;
        rtn.left = `${parentPoint.x + offset.x}px`;
        break;
      case "NE":
        rtn.top = `${parentPoint.y + offset.y}px`;
        rtn.left = `${parentPoint.x + offset.x - linkedBox.width}px`;
        break;
      case "SW":
        rtn.top = `${parentPoint.y + offset.y - linkedBox.height}px`;
        rtn.left = `${parentPoint.x + offset.x + linkedBox.width}px`;
        break;
      case "SE":
        rtn.top = `${parentPoint.y + offset.y - linkedBox.height}px`;
        rtn.left = `${parentPoint.x + offset.x - linkedBox.width}px`;
        break;
    }
  }

  if (isCustomerWeb) {
    rtn.zIndex = "5000";
  }

  return rtn;
};
