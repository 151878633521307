import { nextTick } from "vue";
import { getClosestParentElem, recalculateTeleportedElementPosition } from "@utility/domUtils";
import { CompassPoint } from "@utility/geoUtils";
import debounce from "lodash.debounce";
import { useAppStore } from "@stores/appStore";
import { isNullOrWhiteSpace } from "@utility/stringUtils";

type floatPosition =
  | { parent: CompassPoint; child: CompassPoint }
  | "top"
  | "right"
  | "left"
  | "bottom"
  | "auto";

interface IFloatedComponent {
  viewportId: string;
  position: IDomPosition | null;
  offset?: { x: number; y: number };
  showComponent: boolean;
  showTrigger: ["hover" | "click" | "manual"];
  floatPosition?: floatPosition;
  resizeObserver?: ResizeObserver;
  isCustomerWeb: boolean;
  debouncedOnWindowChangeFunc?: any;
  isDebouncingShow: boolean;
  listenForHoverOnParent: boolean;
  onShowToggle?: (show: boolean) => void;
  onPositionChange?: (position: IDomPosition) => void;
}

const floatedRefs = new Map<string, IFloatedComponent>();

interface initFloatedPositionParams {
  componentId: string;
  viewportId: string;
  position?: floatPosition;
  offset?: { x: number; y: number };
  showTrigger?: "hover" | "click" | "manual" | ("hover" | "click" | "manual")[];
  listenForHoverOnParent?: boolean;
  onShowToggle?: (show: boolean) => void;
  onPositionChange?: (position: IDomPosition) => void;
}

export const mountFloatedPosition = (params: initFloatedPositionParams): void => {
  if (floatedRefs.has(params.componentId)) {
    return console.error(`Floated component with id ${params.componentId} already mounted`);
  }
  const triggerParam: ["hover" | "click" | "manual"] = ["manual"];
  if (params.showTrigger) {
    if (Array.isArray(params.showTrigger)) {
      triggerParam.splice(0, triggerParam.length, ...params.showTrigger);
    } else if (
      typeof params.showTrigger === "string" &&
      ["hover", "click", "manual"].includes(params.showTrigger)
    ) {
      triggerParam.splice(0, triggerParam.length, params.showTrigger);
    }
  }

  const ref = {
    viewportId: params.viewportId,
    position: null,
    offset: params.offset ?? { x: 0, y: 0 },
    showComponent: false,
    floatPosition: params.position ?? "auto",
    showTrigger: triggerParam,
    resizeObserver: undefined,
    isDebouncingShow: false,
    isCustomerWeb: useAppStore().isCustomerWeb(),
    listenForHoverOnParent: params.listenForHoverOnParent ?? true,
    onPositionChange: params.onPositionChange,
    onShowToggle: params.onShowToggle,
  };
  floatedRefs.set(params.componentId, ref);

  if (triggerParam.indexOf("click") > -1) {
    document
      .getElementById(params.componentId)
      ?.parentElement?.addEventListener("click", (e) => onParentClickFunc(params.componentId));
  }

  if (triggerParam.indexOf("hover") > -1) {
    registerHoverEvents(params.componentId, ref);
  }

  registerWindowChangedListener(params.componentId);
};

export const unmountFloatedPosition = (componentId: string): void => {
  const ref = floatedRefs.get(componentId);
  const viewportId = ref?.viewportId;
  if (!viewportId) {
    return;
  }
  document
    .getElementById(componentId)
    ?.parentElement?.removeEventListener("click", (e) => onParentClickFunc(componentId));
  if (ref) {
    unregisterHoverEvents(componentId, ref);
  }
  unregisterWindowChangedListener(componentId);
  if (!isNullOrWhiteSpace(viewportId)) {
    unregisterDocumentClickedListener(componentId, viewportId!);
  }
  unRegisterPopOverContentResizeObserver(componentId);
  floatedRefs.delete(componentId);
};

export const showFloatedComponent = (componentId: string): void => {
  const ref = floatedRefs.get(componentId);
  if (ref === undefined || !ref?.viewportId) {
    console.warn(`Viewport id not found for component id ${componentId}`);
    return;
  }

  floatedRefs.forEach((value, key) => {
    //hide any other floated component that don't have a manual trigger
    if (key !== componentId && value.showTrigger.indexOf("manual") <= -1) {
      hideFloatedComponent(key);
    }
  });

  if (ref.isDebouncingShow) {
    return;
  }

  ref.showComponent = true;
  ref.isDebouncingShow = true;
  ref.onShowToggle?.(true);

  nextTick(() => {
    recalculateFloatedPosition(componentId);
    registerDocumentClickedListener(componentId, ref.viewportId);
    setTimeout(() => {
      if (ref) {
        ref.isDebouncingShow = false;
      }
    }, 100);
    //recalculate once more after the next tick
    //to ensure the position is correct due to vue reactivity
    nextTick(() => {
      recalculateFloatedPosition(componentId);
    });
  });
};

export const hideFloatedComponent = (componentId: string, forceHide: boolean = false): void => {
  const ref = floatedRefs.get(componentId);
  if (isNullOrWhiteSpace(ref?.viewportId)) {
    return;
  }
  if (!forceHide && (ref?.showTrigger.indexOf("manual") ?? -1) > -1) {
    return;
  }

  unregisterWindowChangedListener(componentId);
  unregisterDocumentClickedListener(componentId, ref?.viewportId!);

  if (ref && ref.showComponent && !ref.isDebouncingShow) {
    ref.isDebouncingShow = true;
    ref.showComponent = false;
    ref.debouncedOnWindowChangeFunc = undefined;
    ref.onShowToggle?.(false);
    setTimeout(() => {
      if (ref) {
        ref.isDebouncingShow = false;
      }
    }, 50);
  }
};

export const hideAllFloatedComponents = (): void => {
  for (const floatRef of floatedRefs) {
    hideFloatedComponent(floatRef[0], true);
  }
};

export const recalculateFloatedPosition = (componentId: string): void => {
  const r = floatedRefs.get(componentId);
  if (r && isNullOrWhiteSpace(r.viewportId)) {
    throw new Error(`Viewport id not found for component id ${componentId}`);
  } else if (!r) {
    return;
  }

  const $el = document.getElementById(componentId);
  const $viewportEl = document.getElementById(r.viewportId);
  if ($el === null || $viewportEl === null) {
    return;
  }
  const ref = floatedRefs.get(componentId);
  if (ref) {
    ref.position = recalculateTeleportedElementPosition(
      $el,
      $viewportEl,
      ref.floatPosition ?? "auto",
      ref.isCustomerWeb,
      ref.offset
    );
    ref.onPositionChange?.(ref.position);
  }
};

let scrollProcessing = false;
const scrollProcessor = (event: Event, componentId: string) => {
  if (!scrollProcessing) {
    window.requestAnimationFrame(() => {
      recalculateFloatedPosition(componentId);
      scrollProcessing = false;
    });

    scrollProcessing = true;
  }
};

const onParentClickFunc = (componentId: string) => {
  const ref = floatedRefs.get(componentId);
  if (ref) {
  }
  ref?.showComponent ? hideFloatedComponent(componentId) : showFloatedComponent(componentId);
};

const onWindowChange = (e: Event, componentId: string): void => {
  recalculateFloatedPosition(componentId);
};

const debouncedOnWindowChangeFunc = (e: Event, componentId: string) => () =>
  debounce((e) => onWindowChange(e, componentId), 100);
const registerWindowChangedListener = (componentId: string) => {
  window.onresize = (e) => debouncedOnWindowChangeFunc(e, componentId);
  window.addEventListener("orientationchange", (e) => debouncedOnWindowChangeFunc(e, componentId));
  document.addEventListener("scroll", (e) => scrollProcessor(e, componentId));
};

const unregisterWindowChangedListener = (componentId: string) => {
  window.onresize = null;
  window.removeEventListener("orientationchange", (e) => debouncedOnWindowChangeFunc(e, componentId));
  document.removeEventListener("scroll", (e) => scrollProcessor(e, componentId));
};

const documentClicked = (event: Event, componentId: string, viewportId: string) => {
  if (event?.target && event?.target instanceof HTMLElement) {
    const ref = floatedRefs.get(componentId);
    if (ref?.showComponent) {
      const $parent = getClosestParentElem(event.target, `#${viewportId}`);
      if ($parent === null) {
        hideFloatedComponent(componentId, true);
      }
    }
  }
};

const onParentMouseOutFunc = (componentId: string) => {
  const ref = floatedRefs.get(componentId);
  if (!ref) {
    return;
  }

  if (ref.debouncedOnWindowChangeFunc) {
    ref.debouncedOnWindowChangeFunc.cancel();
  }
  ref.debouncedOnWindowChangeFunc = debounce((e) => hideFloatedComponent(componentId), 500);
  ref.debouncedOnWindowChangeFunc();
};

const onParentMouseOverFunc = (componentId: string) => {
  const ref = floatedRefs.get(componentId);
  if (!ref) {
    return;
  }

  if (ref.debouncedOnWindowChangeFunc) {
    ref.debouncedOnWindowChangeFunc.cancel();
  }
  ref.debouncedOnWindowChangeFunc = debounce((e) => showFloatedComponent(componentId), 500);
  ref.debouncedOnWindowChangeFunc();
};

const registerDocumentClickedListener = (componentId: string, viewportId: string) =>
  document.addEventListener("click", (e) => documentClicked(e, componentId, viewportId));

const unregisterDocumentClickedListener = (componentId: string, viewportId: string) =>
  document.removeEventListener("click", (e) => documentClicked(e, componentId, viewportId));

const registerHoverEvents = (componentId: string, ref: IFloatedComponent) => {
  const $el = document.getElementById(componentId);
  const $popViewEl = document.getElementById(ref.viewportId);
  $popViewEl?.addEventListener("mouseover", () => onParentMouseOverFunc(componentId));
  $popViewEl?.addEventListener("mouseout", () => onParentMouseOutFunc(componentId));

  const $element = ref.listenForHoverOnParent ? $el?.parentElement : $el;
  $element?.addEventListener("mouseover", () => onParentMouseOverFunc(componentId));
  $element?.addEventListener("mouseout", () => onParentMouseOutFunc(componentId));
};

const unregisterHoverEvents = (componentId: string, ref: IFloatedComponent) => {
  const $el = document.getElementById(componentId);
  const $popViewEl = document.getElementById(ref.viewportId);
  $popViewEl?.removeEventListener("mouseover", () => onParentMouseOverFunc(componentId));
  $popViewEl?.removeEventListener("mouseout", () => onParentMouseOutFunc(componentId));

  const $element = ref.listenForHoverOnParent ? $el?.parentElement : $el;
  $element?.removeEventListener("mouseover", () => onParentMouseOverFunc(componentId));
  $element?.removeEventListener("mouseout", () => onParentMouseOutFunc(componentId));
};

const getNewResizeObserver = (componentId: string) =>
  new ResizeObserver((entries) => {
    recalculateFloatedPosition(componentId);
  });

const registerPopOverContentResizeObserver = (componentId: string) => {
  const r = floatedRefs.get(componentId);
  if (!r) {
    throw new Error(`Viewport id not found for component id ${componentId}`);
  }
  if (!r.resizeObserver) {
    setTimeout(() => {
      const $el = document.getElementById(r.viewportId);
      if ($el) {
        r.resizeObserver = getNewResizeObserver(componentId);
        r.resizeObserver.observe($el);
      } else {
        registerPopOverContentResizeObserver(r.viewportId);
      }
    }, 100);
  }
};

const unRegisterPopOverContentResizeObserver = (componentId: string) => {
  const r = floatedRefs.get(componentId);
  const $el = document.getElementById(r?.viewportId ?? "");
  if ($el && r?.resizeObserver) {
    r.resizeObserver.unobserve($el);
  }
};
