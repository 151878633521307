import { ref, reactive } from "vue";
import { defineStore } from "pinia";
import { IPageContentMap, IHtmlContent, IPageContainerKey } from "../interfaces/contentManager/IHtmlContent";
import { fetchData } from "../utility/webServices";
import * as Mustache from "mustache";
import IGetHtmlContentRequest from "../interfaces/site/IGetHtmlContentRequest";
import {
  IHtmlEmbeddedContentItem,
  IEmbeddedContentEdit,
  IPageContentMapItem,
  IEmbeddedContent,
} from "../interfaces/contentManager/IPageContentMapListingItem";
import { IActionResponse, IValidationResult } from "../interfaces/response/IActionResponse";
import { ISiteListingItem } from "../interfaces/site/ISiteListingItem";
import { EmbeddedContentType } from "../appEnum/EmbeddedContentType";

interface IGetHtmlContentRequestKey extends IGetHtmlContentRequest {
  cacheKey: string;
}

export const useContentStore = defineStore("contentStore", () => {
  const myHtmlPageContent = ref<Array<IPageContentMap>>([]);
  const myHtmlContent = ref<Array<IHtmlContent>>([]);
  const cachedHtmlContent = reactive<Map<string, IHtmlContent>>(new Map());
  const intrasitContentKeys = reactive<string[]>([]);
  const contentEditorAnonToken = ref<string>("");
  //#region HtmlContent

  /**
   * Queue Get Html Content
   * @param cacheKeys
   * @returns
   */
  const queueGetHtmlContent = (cacheKeys: string[]): Promise<IHtmlContent[]> => {
    return new Promise<IHtmlContent[]>((resolve) => {
      const queue = () => {
        setTimeout(() => {
          const hasKeys = cacheKeys.filter((k) => cachedHtmlContent.has(k));
          if (hasKeys.length === cacheKeys.length) {
            resolve(cacheKeys.map((k) => cachedHtmlContent.get(k)!));
          } else {
            queue();
          }
        }, 50);
      };

      queue();
    });
  };
  const genGetHtmlContentKey = (brandTypeId: number, contentKey?: string, id?: number): string =>
    `${brandTypeId}|}{|${contentKey ?? ""}|}{|${id ?? 0}`;

  /**
   * Get Html Content
   * @param brandTypeId
   * @param getContentReq
   * @returns
   */
  const getHtmlContent = (
    brandTypeId: number,
    getContentReq: IGetHtmlContentRequest[]
  ): Promise<IHtmlContent[]> => {
    const reqParam = getContentReq.filter(
      (x) => (x.contentKey ?? "").trim() !== "" || (x.id !== undefined && x.id > 0)
    );

    if (reqParam.length <= 0) {
      Promise.resolve([]);
    }
    const contentReq: IGetHtmlContentRequestKey[] = reqParam.map((x) => ({
      cacheKey: genGetHtmlContentKey(brandTypeId, x.contentKey, x.id),
      ...x,
    }));

    const needToFetch = contentReq.filter((x) => !cachedHtmlContent.has(x.cacheKey));
    const alreadyCachedKeys = contentReq
      .filter((x) => cachedHtmlContent.has(x.cacheKey))
      .map((y) => y.cacheKey);

    const currentlyIntransit = needToFetch.filter((x) => intrasitContentKeys.indexOf(x.cacheKey) >= 0);
    if (needToFetch.length <= 0) {
      Promise.resolve(contentReq.map((x) => cachedHtmlContent.has(x.cacheKey)));
    }

    if (currentlyIntransit.length <= 0 && needToFetch.length > 0) {
      needToFetch.forEach((x) => {
        intrasitContentKeys.push(x.cacheKey);
      });

      const req = {
        BrandTypeId: brandTypeId,
        RequestItems: needToFetch.map((x) => ({
          ContentKey: x.contentKey,
          ContentId: x.id,
          ClientKey: x.cacheKey,
        })),
      };

      return fetchData<IHtmlContent[]>("ContentApi/get-html-content-items", req).then((resp) => {
        const response = Array.isArray(resp) ? resp : [];
        needToFetch.forEach((x) => {
          const r = response.find((q) => q.ClientKey === x.cacheKey);
          cachedHtmlContent.set(
            x.cacheKey,
            r ?? ({ HtmlContentHtml: "", HtmlContentId: x.id, HtmlContentName: x.contentKey } as IHtmlContent)
          );
          if (x.cacheKey) {
            const idx = intrasitContentKeys.indexOf(x.cacheKey);
            if (idx >= 0) {
              intrasitContentKeys.splice(idx, 1);
            }
          }
        });

        return response.concat(alreadyCachedKeys.map((x) => cachedHtmlContent.get(x)!));
      });
    } else {
      const intransKeys = currentlyIntransit.map((t) => t.cacheKey);
      const fetchable = needToFetch.filter((x) => intransKeys.indexOf(x.cacheKey) < 0);
      if (fetchable.length > 0) {
        fetchable.forEach((x) => {
          intrasitContentKeys.push(x.cacheKey);
        });
        fetchData<IHtmlContent[]>("ContentApi/get-html-content-items", {
          BrandTypeId: brandTypeId,
          RequestItems: fetchable.map((x) => ({
            ContentKey: x.contentKey,
            ContentId: x.id,
            ClientKey: x.cacheKey,
          })),
        }).then((resp) => {
          const response = Array.isArray(resp) ? resp : [];
          fetchable.forEach((x) => {
            const r = response.find((q) => q.ClientKey === x.cacheKey);
            cachedHtmlContent.set(
              x.cacheKey,
              r ??
                ({ HtmlContentHtml: "", HtmlContentId: x.id, HtmlContentName: x.contentKey } as IHtmlContent)
            );
            if (x.cacheKey) {
              const idx = intrasitContentKeys.indexOf(x.cacheKey);
              if (idx >= 0) {
                intrasitContentKeys.splice(idx, 1);
              }
            }
          });
        });
      }
    }

    return queueGetHtmlContent(contentReq.map((x) => x.cacheKey));
  };

  /**
   * Get All Html Content
   * @returns
   */
  const getAllHtmlContent = async (): Promise<IHtmlContent[] | undefined> => {
    const contentData = await fetchData<Array<IHtmlContent>>("ContentApi/get-all-html-content");
    if (!contentData) {
      return Promise.resolve(undefined);
    }
    myHtmlContent.value = contentData;
    return Promise.resolve(myHtmlContent.value);
  };
  const getHtmlContentByHtmlContentId = (id: number): IHtmlContent | undefined =>
    myHtmlContent.value.find((x) => x.HtmlContentId === id);

  const getHtmlContentByContentKey = (contentKey: string): IHtmlContent | undefined =>
    myHtmlContent.value.find((x) => x.HtmlContentName === contentKey);

  const updateHtmlContent = async (htmlContent: IHtmlContent) =>
    await fetchData<IValidationResult<IHtmlContent>>("ContentApi/update-html-content", htmlContent);

  //#endregion HtmlContent

  //#region PageContennt

  /**
   * Get Html Page Content
   * @param brandTypeId
   * @param id
   * @param contentKey
   * @returns
   */
  const getHtmlPageContent = async (
    brandTypeId: number,
    id?: number,
    contentKey?: string
  ): Promise<IPageContentMap | undefined> => {
    if (brandTypeId === 0 || (id == undefined && contentKey == undefined)) {
      return Promise.resolve(undefined);
    }

    if (myHtmlPageContent.value.length > 0 && id) {
      return getHtmlPageContentByHtmlContentId(brandTypeId, id);
    }
    if (myHtmlPageContent.value.length > 0 && contentKey) {
      return getHtmlPageContentByContentKey(brandTypeId, contentKey);
    }

    const content = await getAllHtmlPageContent();
    if (!content) {
      return Promise.resolve(undefined);
    }
    myHtmlPageContent.value = content;
    return await getHtmlPageContent(brandTypeId, id, contentKey);
  };

  /**
   * Get All Html Page Content
   * @returns
   */
  const getAllHtmlPageContent = async (): Promise<IPageContentMap[] | undefined> => {
    const contentData = await fetchData<Array<IPageContentMap>>("ContentApi/get-all-content");
    if (!contentData) {
      return Promise.resolve(undefined);
    }
    myHtmlPageContent.value = contentData;
    return Promise.resolve(myHtmlPageContent.value);
  };

  const getHtmlPageContentByHtmlContentId = (
    brandTypeId: number,
    id: number
  ): IPageContentMap | undefined => {
    //favor Branded content over content targeting no brand
    const content = myHtmlPageContent.value.find(
      (x) => x.HtmlContentId === id && x.BrandTypeId === brandTypeId
    );
    if (content) {
      return content;
    }
    return myHtmlPageContent.value.find((x) => x.HtmlContentId === id && !x.BrandTypeId);
  };

  /**
   * Get Html Page Content by Content Key
   * @param brandTypeId
   * @param contentKey
   * @returns
   */
  const getHtmlPageContentByContentKey = (
    brandTypeId: number,
    contentKey: string
  ): IPageContentMap | undefined => {
    //favor Branded content over content targeting no brand
    const content = myHtmlPageContent.value.find(
      (x) => x.HtmlContentName === contentKey && x.BrandTypeId === brandTypeId
    );
    if (content) {
      return content;
    }
    return myHtmlPageContent.value.find((x) => x.HtmlContentName === contentKey && !x.BrandTypeId);
  };

  /**
   * Update Content Mapping
   * @param pageContentMap
   * @returns
   */
  const updateContentMapping = async (pageContentMap: IPageContentMapItem) =>
    await fetchData<IValidationResult<IPageContentMapItem>>(
      "ContentApi/update-content-mapping",
      pageContentMap
    );

  /**
   * Get Page Content Map from Page Content Map Id
   * @param pageContentMapId
   * @returns
   */
  const getPageContentMap = async (pageContentMapId: number) =>
    (await fetchData<IPageContentMapItem>(`ContentApi/get-page-content-map/${pageContentMapId}`)) ??
    ([] as IPageContentMapItem[]);

  const getPageContentMaps = async (pageId: number, brandTypeId: number, limitToKeys?: string[]) => {
    if (pageId === 0 || brandTypeId === 0) {
      return Promise.resolve(undefined);
    }

    const content =
      (await fetchData<IPageContentMapItem[]>(`ContentApi/GetContentByPageId`, {
        BrandTypeId: brandTypeId,
        PageId: pageId,
        LimitToKeys: limitToKeys,
      })) ?? ([] as IPageContentMapItem[]);
    return content ?? ([] as IPageContentMapItem[]);
  };
  //#endregion PageContent

  /**
   * Realize Html Content and Render with data
   * @param data
   * @param brandTypeId
   * @param contentKey
   * @param id
   * @returns
   */
  const getHtmlContentRenderedTemplate = async <T>(
    data: T,
    brandTypeId: number,
    contentKey?: string,
    id?: number
  ): Promise<string> => {
    const html = await getHtmlContent(brandTypeId ?? 1, [{ id: id, contentKey: contentKey }]);
    if ((html?.length ?? 0) <= 0) {
      return Promise.resolve("");
    }
    return Promise.resolve(renderHtmlTemplate(html[0].HtmlContentHtml, data));
  };

  /**
   * Update Page Container Key
   * @param pageContainer
   * @returns
   */
  const updatePageContainer = async (pageContainer: IPageContainerKey) =>
    await fetchData<IPageContainerKey>("ContentApi/update-page-container", pageContainer);

  const getPageContainers = async () =>
    await fetchData<IPageContainerKey[]>("ContentApi/get-page-containers");

  /**
   * Get Html Embedded Content from Listing
   * @param embeddedContentItem
   * @returns
   */
  const getHtmlEmbeddedContent = async (embeddedContentItem: IEmbeddedContentEdit) =>
    await fetchData<IHtmlEmbeddedContentItem>(
      `ContentApi/get-html-embedded-content/${embeddedContentItem.PageContentMapId}`,
      {
        HtmlEmbeddedContentId: embeddedContentItem.HtmlEmbeddedContentId ?? null,
      }
    );

  const getFullEmbeddedContentForPageMap = async (pageContentMapId: number): Promise<IEmbeddedContent[]> =>
    (await fetchData<IEmbeddedContent[]>(
      `ContentApi/get-embedded-content-for-page-map/${pageContentMapId}`
    )) ?? ([] as IEmbeddedContent[]);

  /**
   * Validate Embedded Content Signature
   * @param embeddedContentId
   * @param embeddedContentSignature
   * @returns
   */
  const validateEmbeddedContent = async (content: IEmbeddedContent) => {
    if (content === undefined) {
      return Promise.resolve(undefined);
    }
    return await calculateHash(content);
  };

  /**
   * Save Embedded Content
   * @param embeddedContent
   * @returns
   */
  const saveEmbeddedContent = async (embeddedContent: IEmbeddedContentEdit) =>
    await fetchData<IValidationResult<IHtmlEmbeddedContentItem>>(
      "ContentApi/save-embedded-content",
      embeddedContent
    );

  const deleteEmbeddedContent = async (embeddedContent: IEmbeddedContentEdit) =>
    await fetchData<IActionResponse>(`ContentApi/delete-embedded-content`, embeddedContent);

  /**
   * Delete a Page Container Key by PageContainerId
   * @param pageContainerKey
   * @returns
   */
  const deletePageContainerKey = async (pageContainerKey: IPageContainerKey) =>
    await fetchData<IActionResponse>(`ContentApi/delete-page-container/${pageContainerKey.PageContainerId}`);

  /**
   * Get all Sites
   * @returns
   */
  const getAllSites = async (): Promise<ISiteListingItem[] | void> =>
    await fetchData<ISiteListingItem[]>("ContentApi/get-sites");

  /**
   * Render Html Template
   * @param data
   * @param htmlTemplate
   * @returns
   */
  const renderHtmlTemplate = <T>(htmlTemplate: string, data?: T): string =>
    data ? Mustache.to_html(htmlTemplate, data) : htmlTemplate;

  // Calculate SHA-256 hash of the script content
  const calculateHash = async (content: IEmbeddedContent) => {
    const scriptString = `${EmbeddedContentType[content.EmbeddedContentType]}-${
      content.HtmlEmbeddedContentName
    }-${content.HtmlEmbeddedContent}`;

    const encoder = new TextEncoder();
    const dataBuffer = encoder.encode(scriptString);

    const hashBuffer = await crypto.subtle.digest("SHA-256", dataBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = Array.from(hashArray)
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join("")
      .toLowerCase();

    return hashHex;
  };

  const setAnonContentEditorToken = (token: string): void => {
    contentEditorAnonToken.value = token;
  };
  const isContentEditorSession = (): boolean => (contentEditorAnonToken.value ?? "").trim() !== "";

  const clearStoreContent = (): void => {
    myHtmlPageContent.value = [];
    myHtmlContent.value = [];
    cachedHtmlContent.clear();
  };

  return {
    //Html Content
    myHtmlPageContent,
    getAllHtmlPageContent,
    getHtmlPageContent,
    getHtmlContentRenderedTemplate,
    getAllHtmlContent,
    getHtmlContent,
    renderHtmlTemplate,
    updateHtmlContent,

    //Page Content Map / Embedded Content / Page Container
    validateEmbeddedContent,
    getPageContainers,
    updatePageContainer,
    getFullEmbeddedContentForPageMap,
    getHtmlEmbeddedContent,
    saveEmbeddedContent,
    deleteHtmlEmbeddedContent: deleteEmbeddedContent,
    deletePageContainerKey,
    getAllSites,
    getPageContentMap,
    getPageContentMaps,
    updateContentMapping,
    calculateHash,
    setAnonContentEditorToken,
    isContentEditorSession,
    clearStoreContent,
  };
});
