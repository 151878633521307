import { Directive } from "./interfaces/Directive";
import { analyticEcommDefinition, analyticAddZipDefinition, analyticShowImpression, analyticShowImpressionChange } from "./interfaces/analyticDefinitions";

/**
 * Directives to be registered
 */

const directives: Array<Directive> = [
    { Name: "analytic-push", Definition: analyticEcommDefinition },
    { Name: "analytic-add-zip", Definition: analyticAddZipDefinition },
    { Name: "analytic-impressions", Definition: analyticShowImpression },
    { Name: "analytic-impressions-change", Definition: analyticShowImpressionChange },
];


export default directives