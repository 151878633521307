import GoogleAnalytics from "../../googleAnalytics";
import { Ga4AnalyticsProduct } from "./interfaces/Ga4AnalyticsProduct";
import { GoogleAnalytics4 } from "./GoogleAnalytics4";
import { IPushEvent, IPushEcommEvent } from "./interfaces/IPushEvent";
import * as defaults from "./interfaces/DefaultEvents";
import { CheckoutStep } from "./interfaces/CheckoutStep";

const ga = new GoogleAnalytics<GoogleAnalytics4>(GoogleAnalytics4);
let gaCategory: string = "ecommerce";

type promoEventType = "select" | "remove";
type addressEventType = "billing" | "delivery";

/**
 * Set Category for Google Analytics (default to 'ecommerce')
 * @param category - The category to set for Google Analytics.
 */
export const SetCategory = (category: string): void => {
  gaCategory = category;
};

/**
 * Adds products to the cart and pushes an e-commerce event to Google Analytics.
 *
 * @param products - An array of products to be added to the cart.
 */
export const AddToCart = (products: Array<Ga4AnalyticsProduct | unknown>, list?: string): void => {
  PushEcommEvent(defaults.GetAddProductEvent({ Products: products, List: list }));
};

/**
 * Removes products from the cart and pushes an e-commerce event to Google Analytics.
 *
 * @param products - An array of products to be removed from the cart.
 */
export const RemoveFromCart = (products: Array<Ga4AnalyticsProduct | unknown>, list?: string): void => {
  PushEcommEvent(defaults.GetRemoveProductEvent({ Products: products, List: list }));
};

/**
 * Adds address information and pushes an event to Google Analytics.
 *
 * @param addressType - The type of address (billing or delivery).
 * @param zipCode - The zip code of the address.
 */
export const AddAddressInfo = (
  addressType: addressEventType,
  zipCode: string,
  brandType?: string,
  list?: string
): void => {
  switch (addressType) {
    case "billing":
      PushEvent(defaults.GetBillingInfoEvent({ ZipCode: zipCode, List: list, BrandType: brandType }));
      break;
    case "delivery":
      PushEvent(defaults.GetDeliveryInfoEvent({ ZipCode: zipCode, List: list, BrandType: brandType }));
      break;
    default:
      console.error(`Invalid Address Type for google analytics event`);
      return;
  }
};

/**
 * Starts a new application and pushes an event to Google Analytics.
 *
 * @param overrideEvent - (Optional) The override event name.
 */
export const StartNewApplication = (overrideEvent?: string, list?: string) => {
  let event = overrideEvent
    ? ({ Event: overrideEvent } as IPushEvent)
    : defaults.GetStartNewApplicationEvent();
  event.List = list;
  PushEvent(event);
};

/**
 * Sends a product impressions event to Google Analytics.
 *
 * @param products - An array of products for which impressions are being tracked.
 * @param zipCode - (Optional) The zip code associated with the impressions.
 * @param customerType - (Optional) The customer type associated with the impressions.
 * @param productCode - (Optional) The product code associated with the impressions.
 */
export const ProductImpressions = (
  products: Array<Ga4AnalyticsProduct>,
  zipCode?: string,
  customerType?: string,
  productCode?: string,
  list?: string
): void => {
  if (!list && (products ?? []).length > 0) {
    list = products[0].list ?? "";
  }
  PushEcommEvent({
    Action: "impression",
    Products: products,
    ZipCode: zipCode,
    CustomerType: customerType,
    EventName: "product_impression",
    List: list,
  });
};

/**
 * Adds a zip code and pushes an event to Google Analytics.
 *
 * @param zipCode - The zip code to add.
 */
export const AddZipCode = (
  zipCode: string,
  list?: string,
  customerType?: string,
  eventName?: string
): void => {
  var event = defaults.GetSetZipCodeEvent({ ZipCode: zipCode, List: list, CustomerType: customerType ?? "" });
  if (eventName) {
    event.EventName = eventName;
  }
  PushEvent(event);
};

/**
 * Sends a product promotion event to Google Analytics.
 *
 * @param promoCode - The promotion code.
 * @param promoAmount - The promotion amount.
 */
export const ProductPromotion = (
  promoCode: string,
  promoAmount: number,
  eventType: promoEventType = "select",
  list?: string
): void => {
  const event = `${eventType}_promotion`;
  if (!promoCode) {
    console.error(`Invalid Promo code for google analytics event ${event}`);
    return;
  }

  PushEvent({
    EventName: event,
    PromoCode: promoCode,
    PromoAmount: promoAmount,
    List: list,
    Currency: "USD",
  });
};

export const ContactInformation = (
  firstName: string,
  lastName: string,
  phone: string,
  list?: string,
  brandType?: string
): void => {
  PushEvent({
    EventName: "add_contact_info",
    Products: [],
    ZipCode: "",
    OrderAmount: 0,
    DeliveryFee: 0,
    PromoCode: "",
    PromoAmount: 0,
    List: list,
    BrandType: brandType,
  });
};

export const AddPaymentInfo = (pushEvent: IPushEvent): void => {
  PushPageEvent(
    "add_payment_info",
    pushEvent.Products,
    pushEvent.ZipCode,
    pushEvent.OrderAmount,
    pushEvent.DeliveryFee,
    pushEvent.PromoCode,
    pushEvent.PromoAmount,
    pushEvent.BrandType,
    pushEvent.List
  );
};

export const ProductSetup = (pushEvent: IPushEvent): void => {
  PushPageEvent(
    "product_setup",
    pushEvent.Products,
    pushEvent.ZipCode,
    pushEvent.OrderAmount,
    pushEvent.DeliveryFee,
    pushEvent.PromoCode,
    pushEvent.PromoAmount,
    pushEvent.BrandType,
    pushEvent.List
  );
};

export const AddBrandType = (pushEvent: IPushEvent): void => {
  PushPageEvent(
    "select_brand",
    pushEvent.Products,
    pushEvent.ZipCode,
    pushEvent.OrderAmount,
    pushEvent.DeliveryFee,
    pushEvent.PromoCode,
    pushEvent.PromoAmount,
    pushEvent.BrandType,
    pushEvent.List
  );
};

export const PurchaseEvent = (pushEvent: IPushEcommEvent): void => {
  pushEvent.Action = "purchase";
  pushEvent.EventName = "purchase";

  PushEcommEvent(pushEvent);
};

/**
 * Pushes a checkout event to Google Analytics.
 *
 * @param transactionId - The transaction ID.
 * @param customerNumber - The customer number.
 * @param products - An array of products associated with the checkout.
 * @param orderAmount - The order amount.
 * @param deliveryFee - (Optional) The delivery fee.
 * @param promoCode - (Optional) The promo code.
 */
export const Checkout = (
  products: Array<Ga4AnalyticsProduct | unknown>,
  orderAmount: number,
  transactionId?: string,
  customerNumber?: number,
  deliveryFee?: number,
  promoCode?: string,
  list?: string,
  brandType?: string,
  checkoutStep?: CheckoutStep,
  eventName: string = "purchase"
): void => {
  let actionObject = {
    value: orderAmount,
    delivery_fee: deliveryFee,
    currency: "USD",
    coupon: promoCode,
    items: products,
  };

  if (checkoutStep) {
    actionObject["checkout"] = {
      step: checkoutStep.Step,
      option: checkoutStep.Option,
    };
  }
  if (transactionId) {
    actionObject["transaction_id"] = transactionId;
  }
  if (customerNumber) {
    actionObject["customer_number"] = customerNumber;
  }
  if (list) {
    actionObject["list"] = list;
  }
  if (brandType) {
    actionObject["brand"] = brandType;
  }

  ga.pushEvent(eventName, gaCategory, actionObject);
};

/**
 * Pushes a generic event to Google Analytics.
 *
 * @param pushEvent - The event to push.
 */
export const PushEvent = (pushEvent: IPushEvent): void => {
  if (!pushEvent.EventName) {
    console.error(`Invalid Event Name for google analytics event`);
    return;
  }
  PushPageEvent(
    pushEvent.EventName,
    pushEvent.Products,
    pushEvent.ZipCode,
    pushEvent.OrderAmount,
    pushEvent.DeliveryFee,
    pushEvent.PromoCode,
    pushEvent.PromoAmount,
    pushEvent.BrandType,
    pushEvent.List
  );
};

/**
 * Pushes an e-commerce event to Google Analytics.
 *
 * @param ecommEvent - The e-commerce event to push.
 */
export const PushEcommEvent = (ecommEvent: IPushEcommEvent): void => {
  if (!ecommEvent.Action) {
    console.error(`Invalid Action for google analytics event`);
    return;
  }
  if (!ecommEvent.EventName) {
    console.error(`Invalid Event Name for google analytics event`);
    return;
  }

  const actionObject = { items: ecommEvent.Products ?? [] };

  if (ecommEvent.List) {
    actionObject["list"] = ecommEvent.List;
  }
  if (ecommEvent.CustomerType) {
    actionObject["customerType"] = ecommEvent.CustomerType;
  }
  if (ecommEvent.ZipCode) {
    actionObject["zip_code"] = ecommEvent.ZipCode;
  }
  if (ecommEvent.BrandType) {
    actionObject["brand"] = ecommEvent.BrandType;
  }
  if (ecommEvent.Currency) {
    actionObject["currency"] = ecommEvent.Currency;
  }
  if (ecommEvent.Id) {
    actionObject["id"] = ecommEvent.Id;
  }
  if (ecommEvent.Affiliation) {
    actionObject["affiliation"] = ecommEvent.Affiliation;
  }
  if (ecommEvent.Revenue) {
    actionObject["revenue"] = ecommEvent.Revenue;
  }
  if (ecommEvent.Tax) {
    actionObject["tax"] = ecommEvent.Tax;
  }
  if (ecommEvent.Shipping) {
    actionObject["shipping"] = ecommEvent.Shipping;
  }

  ga.pushEcomData(ecommEvent.Action, actionObject, ecommEvent.EventName);
};

/**
 * Pushes a page event to Google Analytics.
 *
 * @param eventName - The name of the event.
 * @param products - (Optional) An array of products associated with the event.
 * @param zipCode - (Optional) The zip code associated with the event.
 * @param orderAmount - (Optional) The order amount associated with the event.
 * @param deliveryFee - (Optional) The delivery fee associated with the event.
 * @param promoCode - (Optional) The promo code associated with the event.
 * @param promoAmount - (Optional) The promo amount associated with the event.
 */
export const PushPageEvent = (
  eventName: string,
  products?: Array<Ga4AnalyticsProduct | unknown>,
  zipCode?: string,
  orderAmount?: number,
  deliveryFee?: number,
  promoCode?: string,
  promoAmount?: number,
  brandType?: string,
  list?: string
): void => {
  let ecommData = {};

  if (products) {
    ecommData["items"] = products;
  }

  if (orderAmount) {
    ecommData["value"] = orderAmount;
    ecommData["currency"] = "USD";
  }

  if (zipCode) {
    ecommData["zip_code"] = zipCode;
  }
  if (deliveryFee && deliveryFee > 0) {
    ecommData["delivery_fee"] = deliveryFee;
  }
  if (promoCode) {
    ecommData["coupon"] = promoCode;
  }
  if (promoAmount) {
    ecommData["discount"] = promoAmount;
  }
  if (brandType) {
    ecommData["brand"] = brandType;
  }
  if (list) {
    ecommData["list"] = list;
  }

  ga.pushEvent(eventName, gaCategory, ecommData);
};
