
import { FileExtensionType } from '../appEnum/FileExtensionType';

export const getFileExtensionMeta = (ext: string) =>{
	ext = ext.toLowerCase();
	const rtnVal = {
		fileExtensionType: FileExtensionType.Unknown,
		fileTypeClass: ''
	};

	const urlRegExpression = /[-A-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-A-Z0-9@:%_+.~#?&//=]*)?/gi;
	const urlRegex = new RegExp(urlRegExpression);

	if (ext.indexOf('youtu') > -1) {
		rtnVal.fileExtensionType = FileExtensionType.YouTube;
		rtnVal.fileTypeClass = 'fa-youtube-play';
	}
	else if (ext.match(urlRegex)) {
		rtnVal.fileExtensionType = FileExtensionType.ExtUrlLink;
		rtnVal.fileTypeClass = 'fa-external-link';
	}
	else {

		switch (ext) {
			case 'xls':
				rtnVal.fileExtensionType = FileExtensionType.XLS;
				rtnVal.fileTypeClass = 'fa-file-excel-o';
				break;
			case 'xlsm':
			case 'xlsx':
				rtnVal.fileExtensionType = FileExtensionType.XLSX;
				rtnVal.fileTypeClass = 'fa-file-excel-o';
				break;
			case 'csv':
				rtnVal.fileExtensionType = FileExtensionType.CSV;
				rtnVal.fileTypeClass = 'fa-file-excel-o';
				break;
			case 'gif':
				rtnVal.fileExtensionType = FileExtensionType.GIF;
				rtnVal.fileTypeClass = 'fa-file-image-o';
				break;
			case 'jpeg':
			case 'jpg':
				rtnVal.fileExtensionType = FileExtensionType.JPG;
				rtnVal.fileTypeClass = 'fa-file-image-o';
				break;

			case 'png':
				rtnVal.fileExtensionType = FileExtensionType.PNG;
				rtnVal.fileTypeClass = 'fa-file-image-o';
				break;
			case 'bmp':
				rtnVal.fileExtensionType = FileExtensionType.BMP;
				rtnVal.fileTypeClass = 'fa-file-image-o';
				break;
			case 'tif':
				rtnVal.fileExtensionType = FileExtensionType.TIFF;
				rtnVal.fileTypeClass = 'fa-file-image-o';
				break;
			case 'mp4':
				rtnVal.fileExtensionType = FileExtensionType.MP4;
				rtnVal.fileTypeClass = 'fa-file-video-o';
				break;
			case 'mpg':
			case 'mpeg':
				rtnVal.fileExtensionType = FileExtensionType.MPEG;
				rtnVal.fileTypeClass = 'fa-file-video-o';
				break;
			case 'avi':
				rtnVal.fileExtensionType = FileExtensionType.AVI;
				rtnVal.fileTypeClass = 'fa-file-video-o';
				break;
			case 'pdf':
				rtnVal.fileExtensionType = FileExtensionType.PDF;
				rtnVal.fileTypeClass = 'fa-file-pdf-o';
				break;
			case 'ppt':
				rtnVal.fileExtensionType = FileExtensionType.PPT;
				rtnVal.fileTypeClass = 'fa-file-powerpoint-o';
				break;
			case 'pptx':
				rtnVal.fileExtensionType = FileExtensionType.PPTX;
				rtnVal.fileTypeClass = 'fa-file-powerpoint-o';
				break;
			case 'mp3':
				rtnVal.fileExtensionType = FileExtensionType.MP3;
				rtnVal.fileTypeClass = 'fa-file-sound-o';
				break;
			case 'wav':
				rtnVal.fileExtensionType = FileExtensionType.WAV;
				rtnVal.fileTypeClass = 'fa-file-sound-o';
				break;
			case 'doc':
				rtnVal.fileExtensionType = FileExtensionType.DOC;
				rtnVal.fileTypeClass = 'fa-file-word-o';
				break;
			case 'docx':
				rtnVal.fileExtensionType = FileExtensionType.DOCX;
				rtnVal.fileTypeClass = 'fa-file-word-o';
				break;
			case 'zip':
				rtnVal.fileExtensionType = FileExtensionType.ZIP;
				rtnVal.fileTypeClass = 'fa-file-zip-o';
				break;
			case 'html':
				rtnVal.fileExtensionType = FileExtensionType.HTML;
				rtnVal.fileTypeClass = 'fa-file-code-o';
				break;
			default:
				rtnVal.fileExtensionType = FileExtensionType.Unknown;
				rtnVal.fileTypeClass = 'fa-file-o';
				break;
		}
	}
	return rtnVal;
}