import { ref } from "vue";
import { defineStore } from "pinia";

import { fetchData } from "../utility/webServices";
import IFrontendRenderedPageMeta from "../interfaces/site/IFrontendRenderedPageMeta";
import { useAppStore } from "./appStore";

export interface IGetPagePageMetaResponse {
  PageMeta: IFrontendRenderedPageMeta;
  InternalMaintenanceBannerMessage?: string;
}

export const usePageStore = defineStore("pageStore", () => {
  const pageMetaLocalStore = ref<IFrontendRenderedPageMeta[]>([]);
  const pages = ref<Map<number, string>>(new Map<number, string>());

  const getPageMeta = (pageBaseUrl: string): Promise<IFrontendRenderedPageMeta | null> => {
    //does the store already have the values we are looking for?
    const pm = pageMetaLocalStore.value.filter((x) => x.DefaultUrlRoute === pageBaseUrl);
    if (pm.length === 1) {
      return Promise.resolve(pm[0]);
    }

    return fetchData<IGetPagePageMetaResponse>("ContentApi/get-page-meta", pageBaseUrl).then(
      (pageMetaResp) => {
        if (pageMetaResp) {
          if (!pageMetaLocalStore.value.some((x) => x.PageId === pageMetaResp.PageMeta.PageId)) {
            pageMetaLocalStore.value.push(pageMetaResp.PageMeta);
          }

          if (pageMetaResp?.InternalMaintenanceBannerMessage ?? "") {
            const appArgs = useAppStore();
            appArgs.setMaintenanceBannerMessage(pageMetaResp?.InternalMaintenanceBannerMessage ?? "");
          }
        }
        return pageMetaResp?.PageMeta ?? null;
      }
    );
  };

  const getAllPagesMap = (): Promise<Map<number, string>> => {
    if (pages.value && pages.value.size > 0) {
      return Promise.resolve(pages.value);
    }
    return fetchData<Map<number, string>>("ContentApi/get-pages").then((pagesMap) => {
      pages.value = pagesMap ?? new Map<number, string>();
      return pages.value;
    });
  }

  return { getPageMeta, getAllPagesMap };
});
