import { BeforeMountDefinition, MountedDefinition } from "./DirectiveDefinition";
import * as ga from "../../../utility/analytics/GoogleAnalytics/googleAnalyticsEvents";
import { IPushEcommEvent } from "../../../utility/analytics/GoogleAnalytics/interfaces/IPushEvent";


const pushImpression = (binding: any) => {
    const impressionEvent = binding?.value();
    if (!impressionEvent) {
        return;
    }

    if (Array.isArray(impressionEvent)) {
        for (const event of impressionEvent) {
            if (!event.Products || event.Products.length === 0) {
                continue;
            }
            ga.ProductImpressions(event.Products);
        }
    }
    else if (impressionEvent.Products && impressionEvent.Products.length > 0) {
        ga.ProductImpressions(impressionEvent.Products);
    }
}

const pushEcomm = (binding: any) => {
    var eventBindings = binding?.value();
    if (!eventBindings) {
        return;
    }

    if (Array.isArray(eventBindings)) {
        for (const event of eventBindings) {
            ga.PushEcommEvent(event as IPushEcommEvent);
        }
    }
    else {
        ga.PushEcommEvent(eventBindings);
    }
};

/**
 * Definition for the analyticEcommDirective.
 */
const analyticEcommDefinition: BeforeMountDefinition = {
    beforeMount(el, binding, vnode, prevVnode) {
        (findElement(el)).addEventListener(binding.arg, () => pushEcomm(binding));
    }
};

/**
 * Definition for the analyticShowImpressionDirective.
 */
const analyticShowImpression: MountedDefinition = {
    mounted(el, binding, vNode, prevVnode) {
        pushImpression(binding);
    },
};

const analyticShowImpressionChange: BeforeMountDefinition = {
    beforeMount(el, binding, vnode, prevVnode) {
        (findElement(el)).addEventListener(binding.arg, () => pushImpression(binding))
    },
}

/**
 * Definition for the analyticAddZipDirective.
 */
const analyticAddZipDefinition: BeforeMountDefinition = {
    beforeMount(el, binding, vnode, prevVnode) {
        (findElement(el)).addEventListener(binding.arg, () => {
            var zipDefinition = binding?.value();
            if (!zipDefinition || !zipDefinition.ZipCode) {
                return;
            }

            ga.AddZipCode(zipDefinition.ZipCode, zipDefinition.List);
        });
    },
};

/**
 * Try to find first valid element (input, button, multiselect) in directive element, if not found return the element itself
 * @param el 
 * @returns 
 */
const findElement = (el: HTMLElement, tryUseFirstElement: boolean = true) => {
    let elements: HTMLElement[] = [];

    //get the first input element on element (components wrapping elements)
    const inputElement = el.querySelector('input');
    if (inputElement && inputElementTypes.includes((inputElement as HTMLInputElement).type)) {
        elements.push(inputElement as HTMLElement);
    }

    const buttonElement = el.querySelector('button');
    if (buttonElement) {
        elements.push(buttonElement as HTMLElement);
    }

    const multiSelectElement = el.querySelector('.multiselect') ?? el.querySelector('.multiselect-wrapper');
    if (multiSelectElement) {
        elements.push(multiSelectElement as HTMLElement);
    }

    if (tryUseFirstElement && elements.length > 1) {
        console.warn(`Multiple elements found in directive element, using first element found - `, elements[0]);
    }

    return tryUseFirstElement ? elements[0] ?? el : el;
}

const inputElementTypes: string[] = ["checkbox", "radio", "text"];

export { analyticEcommDefinition, analyticAddZipDefinition, analyticShowImpression, analyticShowImpressionChange, pushImpression, pushEcomm };