import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 1,
  class: "component-wrapper"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["onClick"]

import { getCurrentInstance, onMounted, watch, ref, provide } from "vue";
import ModalDialog from "./modal.vue";
import { GetModalSize } from "../../appEnum/ModalSize";

interface Props {
  data: any;
  showModal: boolean;
  overrideBackdropZIndex?: number;
  overrideModalZIndex?: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'modalContainer',
  props: {
    data: {},
    showModal: { type: Boolean },
    overrideBackdropZIndex: {},
    overrideModalZIndex: {}
  },
  emits: ["modal-closed"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const $emit = __emit;

const componentToRender = props.data.ComponentToRender;
const modalSize = GetModalSize(props.data.ModalSize);

const closeModal = () => {
  showModal.value = false;
  $emit("modal-closed");
};

const openModal = () => {
  showModal.value = true;
};

const Title = ref(props.data.Title);
const buttons = ref(props.data.Buttons);
const hideHeaderCloseButton = ref(props.data.HideHeaderCloseButton);
const htmlContent = ref(props.data.HtmlContent ? props.data.HtmlContent : false);

const showModal = ref<boolean>(props.showModal);
const hideHeaderCloseBtn = props.data.HideHeaderCloseButton;
const modalOverflow = props.data.ModalOverflow === undefined ? true : props.data.ModalOverflow;

//Make this data available for modal components - Provide here, Inject on component
provide("modalRef", props.data);
provide("data", props.data.Data);

//watch function when invoked from above
watch(showModal, closeModal);

//kick off callback function onMounted() hook
onMounted(() => {
  if (typeof props.data.OnMounted === "function") {
    props.data.OnMounted(getCurrentInstance());
  }
});

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createBlock(ModalDialog, {
    ref: "modal",
    onClose: closeModal,
    data: _ctx.data,
    closeModal: closeModal,
    modalSize: _unref(modalSize),
    modalOverflow: _unref(modalOverflow),
    "modal-z-index": props.overrideModalZIndex,
    "backdrop-z-index": props.overrideBackdropZIndex
  }, {
    header: _withCtx(() => [
      _createElementVNode("span", {
        innerHTML: Title.value,
        class: "modal-title"
      }, null, 8, _hoisted_1),
      (!_unref(hideHeaderCloseBtn))
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
            _createElementVNode("button", {
              type: "button",
              class: "close-btn",
              onClick: closeModal,
              "aria-label": "close"
            }, "x")
          ]))
        : _createCommentVNode("", true)
    ]),
    body: _withCtx(() => [
      (htmlContent.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", {
              innerHTML: htmlContent.value,
              class: "html-content-wrapper"
            }, null, 8, _hoisted_4)
          ]))
        : (_unref(componentToRender))
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(componentToRender)), _mergeProps(_ctx.data, {
                data: _unref(componentToRender).data,
                class: _unref(componentToRender).name
              }), null, 16, ["data", "class"]))
            ]))
          : _createCommentVNode("", true)
    ]),
    footer: _withCtx(() => [
      (buttons.value && buttons.value.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(buttons.value, (footer, index) => {
              return (_openBlock(), _createElementBlock("button", {
                key: index,
                type: "button",
                class: _normalizeClass(footer.classes ?? null),
                onClick: ($event: any) => (footer.action(_ctx.$refs))
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass(footer.icon ?? null)
                }, null, 2),
                _createTextVNode(" " + _toDisplayString(footer.text), 1)
              ], 10, _hoisted_7))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["data", "modalSize", "modalOverflow", "modal-z-index", "backdrop-z-index"])), [
    [_vShow, showModal.value]
  ])
}
}

})