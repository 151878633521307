/**
 * Cleans string of zip+4
 *
 * @param zip - Zip code to be zip5'ed
 * @returns zip5 or empty string
 *
 */
export const boxZip5 = (zip?: string): string => {
  if ((zip ?? "").trim() !== "") {
    if (zip!.length === 5) {
      return zip!;
    }

    const matches = [...zip!.matchAll(/(^\d{5})/g)];
    if (matches.length > 0) {
      return matches[0][1];
    }
  }
  return "";
};

export const isValidZip = (zip: string): boolean => (zip ? /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip) : false);
