import IGoogleAnalyticVersion from "./analytics/GoogleAnalytics/IGoogleAnalyticsVersion";

declare global {
  interface Window {
    dataLayer: any[];
  }
}

/**
 * Main entry point for GoogleAnalytics datalayer pushes
 * Usage: const ga = GoogleAnalytics(GoogleAnalytics4);
 * 		  ga.pushEvent(blah, blah);
 */
export default class GoogleAnalytics<T extends IGoogleAnalyticVersion> {
  /**
   * Constructor for class
   * */
  constructor(type: new () => IGoogleAnalyticVersion) {
    this.ga = new type();
  }

  /**
   * Property to store Google Analytic Version class
   * */
  ga: IGoogleAnalyticVersion;

  /**
   * Used to push events to DataLayer for GA
   * */
  pushEvent = (event: any, category: any, action: any, label?: any, value?: any) =>
    this.ga.pushEvent(event, category, action, label, value);

  /**
   * Used to push EComm data to DataLayer for GA
   * */
  pushEcomData = (action: string, plans: any, event: string, actionField?: any) =>
    this.ga.pushEcomData(action, plans, event, actionField);
}
