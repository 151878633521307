<template>
  <ModalDialog
    ref="modal"
    v-show="showModal"
    @close="closeModal"
    :data="data"
    :closeModal="closeModal"
    :modalSize="modalSize"
    :modalOverflow="modalOverflow"
    :modal-z-index="props.overrideModalZIndex"
    :backdrop-z-index="props.overrideBackdropZIndex"
  >
    <template v-slot:header>
      <span v-html="Title" class="modal-title"></span>
      <span v-if="!hideHeaderCloseBtn">
        <button type="button" class="close-btn" @click="closeModal" aria-label="close">x</button>
      </span>
    </template>

    <template v-slot:body>
      <div v-if="htmlContent">
        <!-- OR Render any Html Content
				**Don't forget to bind any events-->
        <div v-html="htmlContent" class="html-content-wrapper"></div>
      </div>
      <div v-else-if="componentToRender" class="component-wrapper">
        <!-- Render any passed in components -->
        <component :is="componentToRender" v-bind="data" :data="componentToRender.data" :class="componentToRender.name" />
      </div>
    </template>

    <template v-slot:footer>
      <div v-if="buttons && buttons.length > 0">
        <button :key="index" v-for="(footer, index) in buttons" type="button" v-bind:class="footer.classes ?? null" @click="footer.action($refs)">
          <i :class="footer.icon ?? null"></i>
          {{ footer.text }}
        </button>
      </div>
    </template>
  </ModalDialog>
</template>

<script lang="ts" setup>
import { getCurrentInstance, onMounted, watch, ref, provide } from "vue";
import ModalDialog from "./modal.vue";
import { GetModalSize } from "../../appEnum/ModalSize";

interface Props {
  data: any;
  showModal: boolean;
  overrideBackdropZIndex?: number;
  overrideModalZIndex?: number;
}

const props = defineProps<Props>();

const $emit = defineEmits<{
  (e: "modal-closed"): void;
}>();

const componentToRender = props.data.ComponentToRender;
const modalSize = GetModalSize(props.data.ModalSize);

const closeModal = () => {
  showModal.value = false;
  $emit("modal-closed");
};

const openModal = () => {
  showModal.value = true;
};

const Title = ref(props.data.Title);
const buttons = ref(props.data.Buttons);
const hideHeaderCloseButton = ref(props.data.HideHeaderCloseButton);
const htmlContent = ref(props.data.HtmlContent ? props.data.HtmlContent : false);

const showModal = ref<boolean>(props.showModal);
const hideHeaderCloseBtn = props.data.HideHeaderCloseButton;
const modalOverflow = props.data.ModalOverflow === undefined ? true : props.data.ModalOverflow;

//Make this data available for modal components - Provide here, Inject on component
provide("modalRef", props.data);
provide("data", props.data.Data);

//watch function when invoked from above
watch(showModal, closeModal);

//kick off callback function onMounted() hook
onMounted(() => {
  if (typeof props.data.OnMounted === "function") {
    props.data.OnMounted(getCurrentInstance());
  }
});
</script>
