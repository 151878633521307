export function GetModalSize(size): "modal-sm" | "modal-md" | "modal-lg" {
    let mSize: "modal-sm" | "modal-md" | "modal-lg" = "modal-md";
    switch (size) {
        case ModalSize.Small:
            mSize = "modal-sm";
            break;
        case ModalSize.Normal:
            mSize = "modal-md";
            break;
        case ModalSize.Wide:
            mSize = "modal-lg";
            break;
    }
    return mSize;
}

export enum ModalSize {
    Small,
    Normal,
    Wide,
}