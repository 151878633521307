import { randomString } from '../utility/stringUtils';


const genComponentId = (baseId: string): string => {
	if (baseId.trim() === '') {
		baseId = 's1x';
	}

	return `${baseId.trim()}-${randomString(5)}`;
}

export { genComponentId }