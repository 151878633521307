import { ref } from "vue";
import { defineStore } from "pinia";
import { Guid } from "guid-typescript";
import { IAppArgs } from "../interfaces/IAppArgs";
import IFrontendRenderedPageMeta from "../interfaces/site/IFrontendRenderedPageMeta";
import { SiteType } from "../appEnum/SiteType";
import { fetchData } from "../utility/webServices";
import { isInt } from "../utility/numberUtils";

export const useAppStore = defineStore("appArrrgsStore", () => {
  const appArgs = ref<IAppArgs>();
  const setMaintenanceBannerMessage = (msg: string) => {
    const a = appArgs.value;
    if (a) {
      a.InternalMaintenanceBannerMessage = msg;
      appArgs.value = a;
    }
  };

  const setCurrentPageMeta = (pageMeta: IFrontendRenderedPageMeta) => {
    const v = appArgs.value;
    if (v) {
      v.PageMeta = pageMeta;
      appArgs.value = v;
    }
  };

  const witness = (
    loggingActionType: number,
    entityId1?: number,
    entityId2?: number,
    entityGuid?: Guid | string,
    entityId3?: number,
    entityObj?: unknown
  ) => {
    const uri: string[] = [loggingActionType.toString()];
    if (isInt(entityId1)) {
      uri.push(entityId1!.toString());
    }
    if (isInt(entityId2)) {
      uri.push(entityId2!.toString());
    }

    if (entityGuid !== undefined) {
      uri.push(entityGuid.toString());
    }

    if (isInt(entityId3)) {
      uri.push(entityId3!.toString());
    }

    return fetchData<boolean>(`Common/witness/${uri.join("/")}`, entityObj).catch((e) => {
      console.error("Failed to witness", e);
      return false;
    });
  };

  const isCustomerWeb = (): boolean => appArgs.value?.SiteTypeId === SiteType.CustomerWeb;

  const isInternal = (): boolean => appArgs.value?.SiteTypeId === SiteType.S1;

  return {
    isCustomerWeb,
    isInternal,
    appArgs,
    setCurrentPageMeta,
    setMaintenanceBannerMessage,
    witness,
  };
});
