const componentPlayground = () => import("@pages/componentPlayground.vue");
const cacheManager = () => import("@pages/s1Admin/CacheManager.vue");
const retailPriceManager = () => import("@pages/pricing/retailPriceManager.vue");
const zipGroupingManager = () => import("@pages/s1Admin/zipGroupingManager.vue");
const wholesaleInventory = () => import("@pages/wholesale/wholesaleInventoryPage.vue");
const marketingNotificationStats = () => import("@pages/notificationStats/marketingNotificationStats.vue");
const promoCodeAdmin = () => import("@pages/marketing/promoCodeAdmin.vue");
const contentManagerListing = () => import("@pages/marketing/contentManagerListing.vue");
const bolInvoice = () => import("@pages/finance/bolInvoice.vue");
const enrollRejectConfig = () => import("@pages/tickets/rejectConfigPage.vue");
const applications = () => import("@pages/customers/applications.vue");
const s1Login = () => import("@pages/S1Login.vue");
const cohortAdmin = () => import("@pages/marketing/cohortAdmin.vue");
const pcmQuotes = () => import("@pages/pcm/PcmQuotes.vue");
const pcmSettings = () => import("@pages/pcm/PcmSettings.vue");
const teamDirectory = () => import("@pages/hrResources/teamDirectory.vue");
const s1Counter = () => import("@pages/counters/S1Counter.vue");
const incidentManager = () => import("@pages/incidents/IncidentManager.vue");
const opisPrices = () => import("@pages/pricing/opis/opisPrices.vue");
const opisVendorPricing = () => import("@pages/pricing/opis/opisVendorPricing.vue");
const tickerSymbolAdmin = () => import("@pages/s1Admin/tickerSymbolAdmin.vue");
const templateManager = () => import("@pages/s1Admin/templateManager.vue");

const routes = [
  {
    path: "/component-playground",
    name: "Dev Playground",
    component: componentPlayground,
  },
  {
    path: "/cache-manager",
    name: "Cache Manager",
    component: cacheManager,
  },
  {
    path: "/retail-pricing",
    name: "Retail Pricing",
    component: retailPriceManager,
  },
  {
    path: "/zip-areas",
    name: "Zip Areas",
    component: zipGroupingManager,
  },
  {
    path: "/wholesale-inventory",
    name: "Wholesale Inventory",
    component: wholesaleInventory,
  },
  {
    path: "/marketing-notification-stats",
    name: "Marketing Notification Stats",
    component: marketingNotificationStats,
  },
  {
    path: "/promo-codes",
    name: "Promos",
    component: promoCodeAdmin,
  },
  {
    path: "/page-content-manager",
    name: "Content Manager",
    component: contentManagerListing,
  },
  {
    path: "/enroll-reject-config",
    name: "Enroll Reject Config",
    component: enrollRejectConfig,
  },
  {
    path: "/bol-invoice",
    name: "BOL Invoice",
    component: bolInvoice,
  },
  {
    path: "/customer-applications-listing",
    name: "Applications",
    component: applications,
  },
  {
    path: "/login",
    name: "Login",
    component: s1Login,
  },
  {
    path: "/cohorts",
    name: "Cohorts",
    component: cohortAdmin,
  },
  {
    path: "/pcm-quotes/:guid?",
    name: "PCM Quotes",
    component: pcmQuotes,
  },
  {
    path: "/pcm-settings",
    name: "PCM Settings",
    component: pcmSettings,
  },
  {
    path: "/directory",
    name: "Team Member Directory",
    component: teamDirectory,
  },
  {
    path: "/counters/:id/:brand?",
    name: "S1 Counter",
    component: s1Counter,
  },
  {
    path: "/incident-management",
    name: "Incident Manager",
    component: incidentManager,
  },
  {
    path: "/opis-prices",
    name: "OPIS Prices",
    component: opisPrices,
  },
  {
    path: "/opis-vendor-pricing-listing",
    name: "OPIS Vendor Pricing",
    component: opisVendorPricing,
  },
  {
    path: "/manage-ticker-symbols",
    name: "Ticker Symbol Admin",
    component: tickerSymbolAdmin,
  },

  //Until this is fully tested, keep it at _v2
  {
    path: "/templates_v2",
    name: "Templates",
    component: templateManager,
  },

  //{ path: '/:pathMatch(.*)', component:  }
];

export { routes };
