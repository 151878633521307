import ModalContainer from "../components/utility/modalContainer.vue";
import { IModal } from "../interfaces/utility/IModal";
import { createNonRoutedApp } from "../AppStartup";
import { ModalSize } from "../appEnum/ModalSize";
import { createGuid } from "./utility";

export default class UiCommon {
  /**
   * Open a new Modal instance
   * @param modal
   */
  OpenModal(modal: IModal) {
    //remove any existing modal mounts that didn't get destroyed
    let elem = document.querySelector(".modal-mount");
    if (elem) {
      elem.remove();
    }

    //#region Set Defaults
    if (!modal.Buttons || modal.Buttons.length < 1) {
      modal.Buttons = [
        {
          text: "Ok",
          classes: "btn btn-secondary",
          icon: "fa fa-close",
          action: function (ref) {
            if (typeof ref.modal.closeModal === "function") {
              ref.modal.closeModal();
            } else if (typeof ref.modal.$root.closeModal === "function") {
              ref.modal.$root.closeModal();
            } else if (typeof ref.modal.close === "function") {
              ref.modal.close();
            }
          },
        },
      ];
    }
    if (typeof modal.HideHeaderCloseButton === "undefined") {
      modal.HideHeaderCloseButton = false;
    }
    if (!modal.ModalSize) {
      modal.ModalSize = ModalSize.Normal;
    }
    if (modal.ModalOverflow === undefined) {
      modal.ModalOverflow = true;
    }

    //#endregion

    //recreate
    elem = document.createElement("div");
    const newModalId: any = createGuid();
    const modalMount = `modal-mount-${newModalId}`;
    elem.classList.add(modalMount);
    elem.classList.add("modal-mount");
    document.body.appendChild(elem);

    const app = createNonRoutedApp(ModalContainer, {
      data: modal,
      showModal: true,
      modalId: newModalId,
    });
    app.mount(".modal-mount");
  }
}
