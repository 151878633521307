//TODO hook this into routes
const getCustomerPortalInteriorPagesPaths = () => ["/account", "/broker"];

const routes = [
  {
    path: "/component-playground",
    name: "Dev Playground",
    component: () => import("../pages/componentPlayground.vue"),
    meta: {
      foo: "bar",
    },
  },
];

export { routes, getCustomerPortalInteriorPagesPaths };
