import IGoogleAnalyticVersion from "./IGoogleAnalyticsVersion";

/**
 * Google Analytic Version 4
 */
export class GoogleAnalytics4 implements IGoogleAnalyticVersion {
  pushEvent(event: any, category: any, action: any, label?: any, value?: any): void {
    const dataLayer = window.dataLayer || [];
    const ecomEvent = {
      event: event,
      [category]: action,
    };

    //debug
    //console.log("pushEvent", JSON.stringify(ecomEvent));

    dataLayer.push({ ecommerce: null });
    dataLayer.push(ecomEvent);
  }
  pushEcomData(ecommerceAction: string, ecommerceActionObj: any, event: string, actionField?: any): void {
    const dataLayer = window.dataLayer || [];
    const ecomEvent = {
      event: event,
      ecommerce: {
        [ecommerceAction]: ecommerceActionObj,
      },
    };

    //debug
    //console.log("pushEcomData", JSON.stringify(ecomEvent));

    dataLayer.push({ ecommerce: null });
    dataLayer.push(ecomEvent);
  }
}
