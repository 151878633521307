export enum FileExtensionType{
	Unknown = 0,
	PNG = 1,
	JPG = 2,
	GIF = 3,
	PDF = 4,
	DOCX = 5,
	DOC = 6,
	XLSX = 7,
	XLS = 8,
	BMP = 9,
	TIFF = 10,
	MP4 = 11,
	MPEG = 12,
	AVI = 13,
	MP3 = 14,
	ZIP = 15,
	PPTX = 16,
	PPT = 17,
	WAV = 18,
	YouTube = 19,
	HTML = 20,
	ExtUrlLink = 21,
	CSV = 22,
	JSON = 23,
	TXT = 24,
}