import { Guid } from "guid-typescript";

export const randomString = (length = 11): string => {
  let outString = "";
  const inOptions = "abcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < length; i++) {
    outString += inOptions.charAt(Math.floor(Math.random() * inOptions.length));
  }

  return outString;
};

export const htmlEscape = (target?: string | null): string => {
  if (target) {
    return target
      .replace(/&/g, "&amp;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#39;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
  }
  return "";
};

export const htmlUnescape = (target?: string | null): string => {
  if (target) {
    return typeof target !== "string"
      ? target
      : target
          .replace(/&quot;/g, '"')
          .replace(/&apos;/g, "'")
          .replace(/&amp;/g, "&")
          .replace(/&gt;/g, "<")
          .replace(/&lt;/g, ">");
  }
  return "";
};

export const splitOnCamelCase = (text: string): string =>
  text
    .split(/(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join(" ");

export const toTitleCase = (text?: string): string =>
  text?.replace(/\w\S*/g, (word: string, index: number) => {
    return index === 0
      ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }) ?? "";

export const boxGuid = (guid?: Guid | string): string => {
  if (guid === undefined) {
    return "";
  }

  return typeof guid === "string" ? guid : guid.toString();
};

export const isValidGuid = (guid: string): boolean => {
  const guidResult = Guid.parse(guid);
  return Guid.isGuid(guidResult.toString()) && guidResult.toString() !== Guid.EMPTY;
};

export const luhnCheck = (value?: string): boolean => {
  const doLuhnChk = (function (arr) {
    return function (ccNum): boolean {
      var len = ccNum.length,
        bit = 1,
        sum = 0,
        val;

      while (len) {
        val = parseInt(ccNum.charAt(--len), 10);
        sum += (bit ^= 1) ? arr[val] : val;
      }

      return sum !== undefined && sum % 10 === 0;
    };
  })([0, 2, 4, 6, 8, 1, 3, 5, 7, 9]);

  if (value && value.trim() !== "" && value.trim().length >= 12) {
    return doLuhnChk(value);
  }

  return false;
};

export const isValidBankRoutingNumber = (value?: string): boolean => {
  const routingCheck = (routingNbr): boolean => {
    var n = 0;
    for (var i = 0; i < routingNbr.length; i += 3) {
      n +=
        parseInt(routingNbr.charAt(i), 10) * 3 +
        parseInt(routingNbr.charAt(i + 1), 10) * 7 +
        parseInt(routingNbr.charAt(i + 2), 10);
    }
    return n !== 0 && n % 10 === 0;
  };

  return value ? routingCheck(value) : false;
};

export const isValidBankAccountNumber = (value?: string): boolean => {
  const testAccount = (acctNum: string): boolean => {
    if (!acctNum) {
      return false;
    }
    const cleanedAccountNumber = acctNum.replace(/\s/g, "");
    return /^[0-9]{5,17}$/.test(cleanedAccountNumber);
  };

  return value ? testAccount(value) : false;
};

export const isAmexCreditCard = (value?: string): boolean => {
  const testCard = (cardNum): boolean => cardNum && cardNum.replace(/\s/g, "").match(/^(?:3[47][0-9]{13})$/);
  return value ? testCard(this) : false;
};

export const isNullOrWhiteSpace = (val?: string | null): boolean => (val ?? "").trim() === "";
