import Utility from "../../utility/utility";
import { ModalSize } from "../../appEnum/ModalSize";

/**
 * Object passed into uiCommon.OpenModal()
 */
export interface IModal {
  Title?: string | undefined;
  Data?: any | undefined;
  HtmlContent?: string | undefined;
  ComponentToRender?: any | undefined;
  Buttons?: Array<IButton> | undefined;
  HideHeaderCloseButton?: boolean | undefined;
  OnMounted(modalRef): any | undefined;
  ModalSize?: ModalSize | undefined;
  ModalOverflow?: boolean | undefined;
}

export interface IModalPaneConfig {
  title: () => string;
  size?: "modal-lg";
  navigationHash?: string;
  primaryButtonDisabled?: () => boolean | undefined;
  primaryButtonLoading?: () => boolean;
  primaryWizardButtonText?: () => string | undefined;
  primaryWizardButtonAction?: () => void;
  primaryWizardButtonClass?: string;
  primaryButtonIcon?: string;
}

export interface IButton {
  action(modalRef): any;
  text: string;
  classes?: string | undefined;
  icon?: string | undefined;
}

export function determineZIndexForModal(): IZIndex {
  const modals: NodeListOf<Element> = document.querySelectorAll(".modal");
  const util = new Utility();

  let maxBackdropZIndex: number = 1040;
  let maxModalZIndex: number = 1050;

  let modalArray: Array<number> = [];
  if (modals && modals.length > 0) {
    for (let i = 0; i < modals.length; i++) {
      const modal = modals[i];
      modalArray.push(util.getStyle(modal, "z-index"));
    }
    maxModalZIndex = Math.max.apply(
      Math,
      modalArray.map(function (o) {
        return o;
      })
    );
    return {
      modalZ: maxModalZIndex + 20,
      backdropZ: maxModalZIndex + 10,
    };
  }
  //default
  return {
    modalZ: maxModalZIndex,
    backdropZ: maxBackdropZIndex,
  };
}

export interface IZIndex {
  modalZ: number;
  backdropZ: number;
}
