<template>
  <div>
    <Teleport to="body">
      <div
        v-if="showBackDrop"
        :class="['modal-backdrop', 'fade', shown ? 'in' : '', cssClassExtra]"
        :style="{ zIndex: backdropZIndex }"
        @click="closeModal"
      ></div>
      <Transition name="fade">
        <div
          v-if="shown"
          role="dialog"
          class="modal"
          :class="[modalSize, cssClassExtra]"
          :id="modalId"
          :style="{ zIndex: modalZIndex }"
          :aria-labelledby="`modal-title-${compId}`"
          :aria-describedby="`modal-description-${compId}`"
        >
          <!--Click outside of modal window -->
          <div :class="['modal-dialog', modalSize]">
            <div class="modal-content">
              <!-- Modal Header -->
              <div
                class="modal-header"
                :id="`modal-title-${compId}`"
              >
                <slot name="header">
                  <div v-if="title">
                    <Icon
                      v-if="headerIcon"
                      class="pr10"
                      :icon="headerIcon"
                    />
                    <span
                      v-html="title"
                      class="modal-title"
                    ></span>
                  </div>
                  <slot name="header-inner"></slot>
                  <span v-if="!hideHeaderCloseButton">
                    <button
                      type="button"
                      class="close-btn"
                      @click="close"
                      aria-label="close"
                    >
                      x
                    </button>
                  </span>
                </slot>
              </div>
              <!-- Modal Body -->
              <div
                class="modal-body"
                :class="modalBodyClasses"
                :id="`modal-description-${compId}`"
              >
                <slot name="body" />
              </div>
              <!-- Modal Footer -->
              <div
                v-if="props.showFooter && boxBool(props.showFooter)"
                class="modal-footer"
              >
                <slot name="footer-left" />
                <slot name="footer">
                  <ActionButton
                    v-if="variant === 'standard' && boxBool(props.showCloseBtn)"
                    class="btn btn-white"
                    icon="fa-times"
                    @click="close"
                    >&nbsp;{{ closeActionText }}</ActionButton
                  >
                  <ActionButton
                    v-if="primaryActionIcon || primaryActionText"
                    :class="[
                      primaryActionClass,
                      variant === 'fullscreen-minus-header-on-mobile' ? 'btn-xs-block' : '',
                    ]"
                    :icon="primaryActionIcon"
                    :is-loading="primaryActionLoading"
                    :is-disabled="primaryActionDisabled"
                    :text="primaryActionText ? primaryActionText : 'Ok'"
                    v-analytic-push:click="primaryActionAnalytics"
                    @click="$emit('primary-action-clicked')"
                  />
                  <ActionButton
                    v-if="secondaryActionIcon || secondaryActionText"
                    :class="secondaryActionClass"
                    :icon="secondaryActionIcon"
                    :is-loading="secondaryActionLoading"
                    :is-disabled="secondaryActionDisabled"
                    :text="secondaryActionText ? secondaryActionText : 'Ok'"
                    v-analytic-push:click="secondaryActionAnalytics"
                    @click="$emit('secondary-action-clicked')"
                  />
                </slot>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { ref, Transition, Teleport, computed, useSlots } from "vue";
import ActionButton from "@compForm/button.vue";
import Icon from "@compUtility/icon.vue";
import { determineZIndexForModal } from "@interfaces/utility/IModal";
import { genComponentId } from "@components/componentHelpers";
import { boxBool, trueFalseString } from "@utility/boolUtils";
import { useAppStore } from "@stores/appStore";

type modalSizeType = "modal-sm" | "modal-md" | "modal-lg" | "modal-xl";
interface Props {
  data?: any;
  modalZIndex?: any;
  backdropZIndex?: any;
  closeModal?: any;
  modalSize?: modalSizeType;
  modalOverflow?: trueFalseString;
  title?: string;
  showCloseBtn?: trueFalseString;
  primaryActionIcon?: string;
  primaryActionText?: string;
  primaryActionLoading?: boolean;
  primaryActionClass?: string;
  primaryActionDisabled?: boolean;
  secondaryActionIcon?: string;
  secondaryActionText?: string;
  secondaryActionLoading?: boolean;
  secondaryActionClass?: string;
  secondaryActionDisabled?: boolean;
  closeActionText?: string;
  headerIcon?: string;
  variant?: "standard" | "fullscreen-minus-header-on-mobile" | "fullscreen";
  showFooter?: trueFalseString;
  primaryActionAnalytics?: Function;
  secondaryActionAnalytics?: Function;
}

const props = withDefaults(defineProps<Props>(), {
  data: null,
  modalZIndex: null,
  backdropZIndex: null,
  closeModal: true,
  modalSize: "modal-md",
  modalOverflow: false,
  title: "&nbsp;",
  showCloseBtn: true,
  primaryActionIcon: "",
  primaryActionText: "",
  primaryActionLoading: false,
  primaryActionClass: "btn btn-primary",
  primaryActionDisabled: false,
  primaryActionAnalytics: () => {},
  secondaryActionAnalytics: () => {},
  secondaryActionIcon: "",
  secondaryActionText: "",
  secondaryActionLoading: false,
  secondaryActionClass: "btn btn-primary",
  secondaryActionDisabled: false,
  closeActionText: "Close",
  headerIcon: "",
  variant: "standard",
  showFooter: true,
});

const $emit = defineEmits<{
  (e: "modal-closed"): void;
  (e: "primary-action-clicked"): void;
  (e: "secondary-action-clicked"): void;
}>();
const isCustomerWeb = useAppStore().isCustomerWeb();
const { modalZ, backdropZ } = determineZIndexForModal();
const slots = useSlots();
const hideHeaderCloseButton = computed<boolean>(() => props.data?.HideHeaderCloseButton ?? false);
const modalZIndex = computed<number>(() =>
  props.variant === "fullscreen-minus-header-on-mobile" && isCustomerWeb && !props.modalZIndex
    ? undefined
    : props.modalZIndex ?? modalZ
);
const backdropZIndex = computed<number>(() => props.backdropZIndex ?? backdropZ);
const modalSize = computed<modalSizeType>(() => props.modalSize ?? "modal-md");

const closeModal = ref(props.closeModal);
const compId = genComponentId("mdl");
const modalId = ref<string>(`modal-${compId}`);
const shown = ref<boolean>(true);
const showBackDrop = ref<boolean>(shown.value);
const cssClassExtra = ref<string>(
  props.variant === "fullscreen-minus-header-on-mobile" ? "full-screen-990" : ""
);

const modalBodyClasses = computed<string[]>(() => {
  const classes: string[] = [];
  if (boxBool(props.modalOverflow)) {
    classes.push("modal-body-overflow");
  }
  if (!boxBool(props.showFooter)) {
    classes.push("no-footer");
  }
  return classes;
});

const close = () => {
  shown.value = false;
  setTimeout(() => {
    if (!shown.value) {
      showBackDrop.value = false;
    }
    $emit("modal-closed");
  }, 100);
};

defineExpose({
  close,
});
</script>
<style lang="less">
@import "@css/utilityClasses/flexUtils.less";
</style>
<style lang="less">
@media (max-width: 992px) {
  body:has(.modal-md.full-screen-990) section.body {
    display: none;
  }
}
</style>
<style lang="less" scoped>
.modal {
  display: block;
}

.modal-backdrop {
  z-index: 1000;
}

.modal-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.modal-body {
  overflow-x: clip;
}

.modal .modal-header .close-btn {
  float: right !important;
  border: none;
  background: inherit;
  font-weight: 500;
  font-size: 1.1rem;
  padding: 2px 5px !important;
}

@media (min-width: 768px) {
  .close-btn {
    padding: 2px 5px !important;
  }
}

//overrides some garbage in the customer web
.modal-header:before,
.modal-header:after {
  display: inherit !important;
  content: none !important;
}

.modal-body-overflow {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

.modal-body-overflow.no-footer {
  max-height: calc(100vh - 110px);
  overflow-y: auto;
}

@media (max-width: 992px) {
  .modal-backdrop.full-screen-990 {
    display: none !important;
  }

  .modal.full-screen-990 {
    &.modal {
      top: var(--mobile-header-height) !important;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9998;
    }
    .modal-dialog {
      width: 100% !important;
      height: 100% !important;

      margin: 0;
      //border: 1px dotted goldenrod;
    }

    .modal-body {
      //height: calc(100vh - var(--mobile-header-height) - );
      //max-height: calc(100vh - var(--mobile-header-height)) !important;
      padding: 0 15px 15px;
      width: 100%;
      height: 100%;
    }
    .modal-overflow {
      overflow-y: auto !important;
      max-height: auto !important;
      height: 100%;
    }
    .modal-header {
      border-bottom: 0;
    }

    .modal-content {
      box-shadow: none;
      border-radius: 0;
      border: none;
      background-color: var(--base-page-bg-color);
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: flex-start;
      align-items: stretch;
    }

    .modal-footer {
      z-index: 1;
    }
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
}

@media (min-width: 992px) {
  .modal-lg:not(.modal) {
    width: 99% !important;
    max-width: 1070px !important;
  }
  .modal-xl:not(.modal) {
    width: 97% !important;
    max-width: 1500px !important;
  }
}
</style>
