import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderSlot as _renderSlot, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Transition as _Transition, createVNode as _createVNode, Teleport as _Teleport } from "vue"

const _hoisted_1 = ["id", "aria-labelledby", "aria-describedby"]
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = ["id"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["id"]
const _hoisted_8 = {
  key: 0,
  class: "modal-footer"
}

import { ref, Transition, Teleport, computed, useSlots } from "vue";
import ActionButton from "@compForm/button.vue";
import Icon from "@compUtility/icon.vue";
import { determineZIndexForModal } from "@interfaces/utility/IModal";
import { genComponentId } from "@components/componentHelpers";
import { boxBool, trueFalseString } from "@utility/boolUtils";
import { useAppStore } from "@stores/appStore";

type modalSizeType = "modal-sm" | "modal-md" | "modal-lg" | "modal-xl";
interface Props {
  data?: any;
  modalZIndex?: any;
  backdropZIndex?: any;
  closeModal?: any;
  modalSize?: modalSizeType;
  modalOverflow?: trueFalseString;
  title?: string;
  showCloseBtn?: trueFalseString;
  primaryActionIcon?: string;
  primaryActionText?: string;
  primaryActionLoading?: boolean;
  primaryActionClass?: string;
  primaryActionDisabled?: boolean;
  secondaryActionIcon?: string;
  secondaryActionText?: string;
  secondaryActionLoading?: boolean;
  secondaryActionClass?: string;
  secondaryActionDisabled?: boolean;
  closeActionText?: string;
  headerIcon?: string;
  variant?: "standard" | "fullscreen-minus-header-on-mobile" | "fullscreen";
  showFooter?: trueFalseString;
  primaryActionAnalytics?: Function;
  secondaryActionAnalytics?: Function;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'modal',
  props: {
    data: { default: null },
    modalZIndex: { default: null },
    backdropZIndex: { default: null },
    closeModal: { default: true },
    modalSize: { default: "modal-md" },
    modalOverflow: { type: [Boolean, String], default: false },
    title: { default: "&nbsp;" },
    showCloseBtn: { type: [Boolean, String], default: true },
    primaryActionIcon: { default: "" },
    primaryActionText: { default: "" },
    primaryActionLoading: { type: Boolean, default: false },
    primaryActionClass: { default: "btn btn-primary" },
    primaryActionDisabled: { type: Boolean, default: false },
    secondaryActionIcon: { default: "" },
    secondaryActionText: { default: "" },
    secondaryActionLoading: { type: Boolean, default: false },
    secondaryActionClass: { default: "btn btn-primary" },
    secondaryActionDisabled: { type: Boolean, default: false },
    closeActionText: { default: "Close" },
    headerIcon: { default: "" },
    variant: { default: "standard" },
    showFooter: { type: [Boolean, String], default: true },
    primaryActionAnalytics: { type: Function, default: () => {} },
    secondaryActionAnalytics: { type: Function, default: () => {} }
  },
  emits: ["modal-closed", "primary-action-clicked", "secondary-action-clicked"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props;

const $emit = __emit;
const isCustomerWeb = useAppStore().isCustomerWeb();
const { modalZ, backdropZ } = determineZIndexForModal();
const slots = useSlots();
const hideHeaderCloseButton = computed<boolean>(() => props.data?.HideHeaderCloseButton ?? false);
const modalZIndex = computed<number>(() =>
  props.variant === "fullscreen-minus-header-on-mobile" && isCustomerWeb && !props.modalZIndex
    ? undefined
    : props.modalZIndex ?? modalZ
);
const backdropZIndex = computed<number>(() => props.backdropZIndex ?? backdropZ);
const modalSize = computed<modalSizeType>(() => props.modalSize ?? "modal-md");

const closeModal = ref(props.closeModal);
const compId = genComponentId("mdl");
const modalId = ref<string>(`modal-${compId}`);
const shown = ref<boolean>(true);
const showBackDrop = ref<boolean>(shown.value);
const cssClassExtra = ref<string>(
  props.variant === "fullscreen-minus-header-on-mobile" ? "full-screen-990" : ""
);

const modalBodyClasses = computed<string[]>(() => {
  const classes: string[] = [];
  if (boxBool(props.modalOverflow)) {
    classes.push("modal-body-overflow");
  }
  if (!boxBool(props.showFooter)) {
    classes.push("no-footer");
  }
  return classes;
});

const close = () => {
  shown.value = false;
  setTimeout(() => {
    if (!shown.value) {
      showBackDrop.value = false;
    }
    $emit("modal-closed");
  }, 100);
};

__expose({
  close,
});

return (_ctx: any,_cache: any) => {
  const _directive_analytic_push = _resolveDirective("analytic-push")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      (showBackDrop.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(['modal-backdrop', 'fade', shown.value ? 'in' : '', cssClassExtra.value]),
            style: _normalizeStyle({ zIndex: backdropZIndex.value }),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (closeModal.value && closeModal.value(...args)))
          }, null, 6))
        : _createCommentVNode("", true),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (shown.value)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                role: "dialog",
                class: _normalizeClass(["modal", [modalSize.value, cssClassExtra.value]]),
                id: modalId.value,
                style: _normalizeStyle({ zIndex: modalZIndex.value }),
                "aria-labelledby": `modal-title-${_unref(compId)}`,
                "aria-describedby": `modal-description-${_unref(compId)}`
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(['modal-dialog', modalSize.value])
                }, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", {
                      class: "modal-header",
                      id: `modal-title-${_unref(compId)}`
                    }, [
                      _renderSlot(_ctx.$slots, "header", {}, () => [
                        (_ctx.title)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                              (_ctx.headerIcon)
                                ? (_openBlock(), _createBlock(Icon, {
                                    key: 0,
                                    class: "pr10",
                                    icon: _ctx.headerIcon
                                  }, null, 8, ["icon"]))
                                : _createCommentVNode("", true),
                              _createElementVNode("span", {
                                innerHTML: _ctx.title,
                                class: "modal-title"
                              }, null, 8, _hoisted_5)
                            ]))
                          : _createCommentVNode("", true),
                        _renderSlot(_ctx.$slots, "header-inner"),
                        (!hideHeaderCloseButton.value)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                              _createElementVNode("button", {
                                type: "button",
                                class: "close-btn",
                                onClick: close,
                                "aria-label": "close"
                              }, " x ")
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ], 8, _hoisted_3),
                    _createElementVNode("div", {
                      class: _normalizeClass(["modal-body", modalBodyClasses.value]),
                      id: `modal-description-${_unref(compId)}`
                    }, [
                      _renderSlot(_ctx.$slots, "body")
                    ], 10, _hoisted_7),
                    (props.showFooter && _unref(boxBool)(props.showFooter))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _renderSlot(_ctx.$slots, "footer-left"),
                          _renderSlot(_ctx.$slots, "footer", {}, () => [
                            (_ctx.variant === 'standard' && _unref(boxBool)(props.showCloseBtn))
                              ? (_openBlock(), _createBlock(ActionButton, {
                                  key: 0,
                                  class: "btn btn-white",
                                  icon: "fa-times",
                                  onClick: close
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" " + _toDisplayString(_ctx.closeActionText), 1)
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            (_ctx.primaryActionIcon || _ctx.primaryActionText)
                              ? _withDirectives((_openBlock(), _createBlock(ActionButton, {
                                  key: 1,
                                  class: _normalizeClass([
                      _ctx.primaryActionClass,
                      _ctx.variant === 'fullscreen-minus-header-on-mobile' ? 'btn-xs-block' : '',
                    ]),
                                  icon: _ctx.primaryActionIcon,
                                  "is-loading": _ctx.primaryActionLoading,
                                  "is-disabled": _ctx.primaryActionDisabled,
                                  text: _ctx.primaryActionText ? _ctx.primaryActionText : 'Ok',
                                  onClick: _cache[1] || (_cache[1] = ($event: any) => ($emit('primary-action-clicked')))
                                }, null, 8, ["class", "icon", "is-loading", "is-disabled", "text"])), [
                                  [_directive_analytic_push, _ctx.primaryActionAnalytics, "click"]
                                ])
                              : _createCommentVNode("", true),
                            (_ctx.secondaryActionIcon || _ctx.secondaryActionText)
                              ? _withDirectives((_openBlock(), _createBlock(ActionButton, {
                                  key: 2,
                                  class: _normalizeClass(_ctx.secondaryActionClass),
                                  icon: _ctx.secondaryActionIcon,
                                  "is-loading": _ctx.secondaryActionLoading,
                                  "is-disabled": _ctx.secondaryActionDisabled,
                                  text: _ctx.secondaryActionText ? _ctx.secondaryActionText : 'Ok',
                                  onClick: _cache[2] || (_cache[2] = ($event: any) => ($emit('secondary-action-clicked')))
                                }, null, 8, ["class", "icon", "is-loading", "is-disabled", "text"])), [
                                  [_directive_analytic_push, _ctx.secondaryActionAnalytics, "click"]
                                ])
                              : _createCommentVNode("", true)
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ], 2)
              ], 14, _hoisted_1))
            : _createCommentVNode("", true)
        ]),
        _: 3
      })
    ]))
  ]))
}
}

})