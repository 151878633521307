/*   
 
               .---. .---.
              :     : o   :    me want cookie!
          _..-:   o :     :-.._    /
      .-''  '  `---' `---' "   ``-.
    .'   "   '  "  .    "  . '  "  `.
   :   '.---.,,.,...,.,.,.,..---.  ' ;
   `. " `.                     .' " .'
    `.  '`.                   .' ' .'
     `.    `-._           _.-' "  .'  .----.
       `. "    '"--...--"'  . ' .'  .'  o   `.
       .'`-._'    " .     " _.-'`. :       o  :
 jgs .'      ```--.....--'''    ' `:_ o       :
   .'    "     '         "     "   ; `.;";";";'
  ;         '       "       '     . ; .' ; ; ;
 ;     '         '       '   "    .'      .-'
 '  "     "   '      "           "    _.-'


 */


export default class CookieManager {
    constructor(name?: string) {
        this._cookie = "";
        this._cookieName = "";
        if (name) {
            this._cookieName = name;
            this.GetCookie(name).then();
        }
    }

    private _cookieName: string;
    private _cookie: string;


    public get CookieName(): string {
        if (!this._cookieName) {
            throw new Error("Please set cookie name first");
        }
        return this._cookieName;
    }
    public set CookieName(value: string) {
        this._cookieName = value;
        this.GetCookie(value);
    }

    public get Cookie(): string {
        if (!this.IsCookieValid) {
            throw new Error("No valid cookie found");
        }
        return this._cookie;
    }
    public get IsCookieValid(): boolean {
        return this._cookie !== undefined || this._cookie !== null;
    }

    private async GetCookie(name: string): Promise<void> {
        var self = this;
        return new Promise(function (res, rej) {
            self._cookie = document.cookie.split('; ').reduce((r, v) => {
                const parts = v.split('=')
                return parts[0] === name ? decodeURIComponent(parts[1]) : r
            }, '')
        });
    }

    public SetCookie(name: string, value: any, expire: number, path: string = "/"): void {
        var expires = "";
        if (expire) {
            var date = new Date();
            date.setTime(date.getTime() + (expire * 60 * 1000));
            expires = "expires=" + date.toUTCString();
        }
        document.cookie = `${name}=${(value || "")}; ${expires}; path=${path}`;
    }
}