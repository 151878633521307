import {
  importS1WebLegacyCssBundles,
  importS1WebLegacyJsBundles,
  importCustomerPortalJsBundles,
  importCustomerPortalCssBundles,
  importFormBuilderJsBundles,
} from "./LegacyBundleImports";
import ILegacyFunctionCall from "./interfaces/ILegacyFunctionCall";

const execLegacyS1Func = (legacyFuncArgs: ILegacyFunctionCall): void => {
  ensureBundle("css", legacyFuncArgs.initFunctionLibName);

  ensureBundle("js", legacyFuncArgs.initFunctionLibName).then(() => {
    const legacyFuncCallText = `${legacyFuncArgs.initFunctionLibName}(${legacyFuncArgs.initArgs
      ?.map((m) => JSON.stringify(m))
      ?.join(",")})`;
    try {
      new Function(legacyFuncCallText)();
    } catch (e) {
      console.error(
        `Error executing legacy function: ${legacyFuncArgs.initFunctionLibName}, ${
          e && e["stack"] ? "stack: " + e["stack"] : ""
        } full args:`,
        legacyFuncCallText
      );
    }
  });
};

const TICKER_DISPLAY_LIB = "hana.epc.tickerdisplay";
const FORM_BUILDER_INIT_FUNCTIONS = new Set<string>([
  "hana.pages.formBuilderEditor.init",
  "hana.pages.formListing.init",
]);

const ensureBundle = (bundleType: "css" | "js", libraryFunction: string): Promise<any> => {
  const parts = libraryFunction.split(".");
  if (parts.length <= 1) {
    return Promise.resolve();
  }

  //load the formbuilder bundles after the man lib
  if (bundleType === "js" && FORM_BUILDER_INIT_FUNCTIONS.has(libraryFunction)) {
    return importS1WebLegacyJsBundles(libraryFunction).then(() => importFormBuilderJsBundles());
  }

  let libName = parts[0];

  //load the EPC specialized bundle
  if (libraryFunction === "hana.pages.tickerDisplay.init") {
    libName = TICKER_DISPLAY_LIB;
  }

  if (libName === "hana" || libName === TICKER_DISPLAY_LIB) {
    return bundleType === "css" ? importS1WebLegacyCssBundles() : importS1WebLegacyJsBundles(libraryFunction);
  } else if (libName === "cw") {
    return bundleType === "css" ? importCustomerPortalCssBundles() : importCustomerPortalJsBundles();
  }

  return Promise.resolve();
};

export { execLegacyS1Func };
