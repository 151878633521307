export const isNumber = (val?: string | number | null | void): boolean =>
  typeof val !== "undefined" &&
  val !== null &&
  !(typeof val === "string" && val.trim() === "") &&
  !isNaN(+val);

export const getInt = (val: string): null | number => (!isNaN(+val) ? parseInt(val) : null);

export const getFloat = (val: string | number | null | undefined): null | number => {
  if (typeof val === "undefined" || val === null) {
    return null;
  }
  if (typeof val === "number") {
    return val;
  }
  return !isNaN(+val) ? parseFloat(val) : null;
};

/**
 * Returns is a value is an integer.
 *
 * @remarks
 * 	Number.isInteger('123')
 *  > false
 *  this function will return true in this case
 * @returns `true` if value is an integer
 *
 */
//
export const isInt = (val?: string | number | null | void): boolean => {
  if (isNumber(val)) {
    if (typeof val === "string") {
      return val.match(/[^\d]+/g) === null;
    } else {
      return Number.isInteger(val);
    }
  }
  return false;
};

export const roundPlaces = (number: number, decimalPlaces: number) =>
  Number(Math.round(Number(number + "e" + decimalPlaces)) + "e" + decimalPlaces * -1);

export const randomInt = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
