import { IPushEcommEvent, IPushEvent } from "./IPushEvent";

/* Default Events*/
export const GetBillingInfoEvent = (event: IPushEvent): IPushEvent => {
    return {
        ...event,
        EventName: "add_billing_info"
    } as IPushEvent;
}

export const GetDeliveryInfoEvent = (event: IPushEvent): IPushEvent => {
    return {
        ...event,
        EventName: "add_delivery_info"
    } as IPushEvent;
}

export const GetPromoCodeEvent = (event: IPushEvent): IPushEvent => {
    return {
        ...event,
        EventName: "select_promotion"
    } as IPushEvent;

}

export const GetPaymentInfoEvent = (event: IPushEvent): IPushEvent => {
    return {
        ...event,
        EventName: "add_payment_info"
    } as IPushEvent;

}

export const GetPurchaseEvent = (event: IPushEcommEvent): IPushEcommEvent => {
    return {
        ...event,
        Action: "purchase",
        EventName: "purchase"
    } as IPushEcommEvent;
}

export const GetStartNewApplicationEvent = (): IPushEvent => {
    return {
        EventName: "start_new_application",
    } as IPushEvent;
}

export const GetContactInformationEvent = (event: IPushEcommEvent): IPushEcommEvent => {
    return {
        ...event,
        Action: "ecommerce",
        EventName: "contact_information"
    } as IPushEcommEvent;
}

export const GetAutoStartApplicationEvent = (event: IPushEcommEvent): IPushEcommEvent => {
    return {
        ...event,
        Action: "ecommerce",
        EventName: "autostart_application"
    } as IPushEcommEvent;
}


export const GetAddProductEvent = (event: IPushEcommEvent): IPushEcommEvent => {
    return {
        ...event,
        Action: "add",
        EventName: "addToCart"
    } as IPushEcommEvent;
}
export const GetRemoveProductEvent = (event: IPushEcommEvent): IPushEcommEvent => {
    return {
        ...event,
        Action: "remove",
        EventName: "removeFromCart",
    } as IPushEcommEvent;
}

export const GetSetZipCodeEvent = (event: IPushEvent): IPushEvent => {
    return {
        ...event,
        EventName: "set zipcode",
    } as IPushEvent;
}

export const GetProductImpressionEvent = (event: IPushEcommEvent): IPushEcommEvent => {
    return {
        ...event,
        Action: "impression",
        EventName: "productImpression",
    } as IPushEcommEvent;
}