<template>
  <div
    v-if="Array.isArray(props.caretOptions) && props.caretOptions.length > 0"
    :class="{
      'btn-group': true,
      flex: true,
      open: caretOptionsOpen,
    }"
  >
    <button
      v-if="!caretClickOnly"
      type="button"
      :class="btnClassComputed"
      :aria-disabled="boxBool(props.isDisabled)"
      @click="onClickEvent()"
    >
      <Icon
        v-if="props.icon && !isLoading"
        :icon="props.icon ?? ''"
        :class="{ pr10: (props.text ?? '').length > 0 }"
      />
      <Icon
        v-if="isLoading"
        icon="fa-circle-o-notch"
        :animate="true"
        :class="{ pr10: (props.text ?? '').length > 0 }"
      />
      <slot>
        <span v-html="props.text ?? ''" />
      </slot>
    </button>
    <button
      type="button"
      :class="btnClassComputed"
      class="btn dropdown-toggle"
      :id="caretBtnId"
      aria-haspopup="true"
      :aria-expanded="caretOptionsOpen"
      :aria-disabled="boxBool(props.isDisabled)"
      @click.prevent="onCaretClick"
    >
      <Icon
        v-if="caretClickOnly && props.icon && !isLoading"
        :icon="props.icon ?? ''"
        class="pr10"
      />
      <Icon
        v-if="caretClickOnly && isLoading"
        icon="fa-circle-o-notch"
        :animate="true"
        class="pr10"
      />
      <slot v-if="caretClickOnly"><span v-html="props.text ?? ''" /></slot>
      &nbsp;<span class="caret"></span>
      <span class="sr-only">Toggle Dropdown</span>
    </button>
    <Teleport to="body">
      <ul
        :class="['caret-dropdown-menu', !caretMenuShown ? 'hidden' : undefined]"
        :style="calcPositionObj ?? {}"
        :id="`${caretBtnId}-menu`"
      >
        <li
          v-for="(option, index) in props.caretOptions"
          :key="index"
          :class="{ divider: option.isDivider }"
          :id="option.id"
        >
          <a
            href="#"
            v-if="typeof option.action === 'function'"
            @click.prevent="onActionClicked(option)"
          >
            <Icon
              v-if="option.icon"
              :icon="option.icon"
              :class="{ mr5: option.text }"
            />
            <span v-html="option.text" />
          </a>
          <span v-else>
            <Icon
              v-if="option.icon"
              :icon="option.icon"
              :class="{ mr5: option.text }"
            />
            <span v-html="option.text" />
          </span>
        </li>
      </ul>
    </Teleport>
  </div>
  <button
    v-else
    type="button"
    :class="btnClassComputed"
    @click="onClickEvent()"
  >
    <Icon
      v-if="props.icon && !isLoading"
      :icon="props.icon ?? ''"
      :class="{ pr10: (props.text ?? '').length > 0 }"
    />
    <Icon
      v-if="isLoading"
      icon="fa-circle-o-notch"
      :animate="true"
      :class="{ pr10: (props.text ?? '').length > 0 }"
    />
    <slot><span v-html="props.text ?? ''" /></slot>
  </button>
</template>

<script lang="ts" setup>
import { onUnmounted, ref, computed, Teleport, onMounted } from "vue";
import { boxBool, trueFalseString } from "@utility/boolUtils";
import { genComponentId } from "@components/componentHelpers";
import IButtonCaretOption from "./interfaces/IButtonCaretOption";
import Icon from "@compUtility/icon.vue";
import {
  hideFloatedComponent,
  mountFloatedPosition,
  showFloatedComponent,
  unmountFloatedPosition,
} from "@compUtility/composables/floatedPosition";

const emit = defineEmits(["click"]);

interface Props {
  btnClass?: string | string[];
  icon?: string;
  text?: string;
  isLoading?: trueFalseString;
  isDisabled?: trueFalseString;
  caretClickOnly?: trueFalseString;
  caretOptions?: IButtonCaretOption[];
}

const props = withDefaults(defineProps<Props>(), {
  btnClass: "",
  icon: "",
  text: "",
  isLoading: false,
  isDisabled: false,
  caretClickOnly: false,
  caretOptions: undefined,
});

const caretClickOnly = ref<boolean>(boxBool(props.caretClickOnly));
const caretOptionsOpen = ref(false);
const caretBtnId = ref(genComponentId("btn-crt"));
const calcPositionObj = ref<IDomPosition | null>(null);

const btnClassComputed = computed<string>(() => {
  const rtn: string[] = [];
  if (props.btnClass !== undefined && Array.isArray(props.btnClass)) {
    props.btnClass.forEach((x) => rtn.push(x));
  } else if (
    props.btnClass !== undefined &&
    typeof props.btnClass === "string" &&
    props.btnClass.trim() !== ""
  ) {
    rtn.push(props.btnClass);
  } else {
    rtn.push("btn-primary");
  }

  if (boxBool(props.isLoading) || boxBool(props.isDisabled)) {
    rtn.push("disabled");
  }

  if (rtn.indexOf("btn") <= -1) {
    rtn.unshift("btn");
  }

  return rtn.join(" ");
});

const caretMenuShown = computed<boolean>(() => caretOptionsOpen.value && calcPositionObj.value !== null);

const docListener = (e: Event) => {
  if (caretOptionsOpen.value) {
    caretOptionsOpen.value = false;
    document.removeEventListener("click", docListener);
  }
};

const onCaretClick = () => {
  var orgOpenVal = caretOptionsOpen.value;
  caretOptionsOpen.value = !caretOptionsOpen.value;
  !orgOpenVal ? showFloatedComponent(caretBtnId.value) : hideFloatedComponent(caretBtnId.value);
  return false;
};

const onClickEvent = () => {
  if (!boxBool(props.isLoading) && !boxBool(props.isDisabled)) {
    emit("click");
  }
};

const onActionClicked = (option: IButtonCaretOption) => {
  if (typeof option.action !== "function") {
    return;
  }

  option.action();
  emit("click", option);
};

onMounted(() => {
  if (boxBool(props.caretClickOnly) || (Array.isArray(props.caretOptions) && props.caretOptions.length > 0)) {
    mountFloatedPosition({
      componentId: caretBtnId.value,
      viewportId: caretBtnId.value + "-menu",
      position: { parent: "SE", child: "NE" },
      showTrigger: "manual",
      onPositionChange: (position) => {
        calcPositionObj.value = position;
      },
      onShowToggle: (show) => {
        caretOptionsOpen.value = show;
      },
    });
  }
});

onUnmounted(() => {
  unmountFloatedPosition(caretBtnId.value);
});
</script>
<style scoped>
.btn-rounded {
  border-radius: 50px;
}

.btn-link.disabled {
  background-color: transparent;
}

ul.caret-dropdown-menu {
  border: none;
  -webkit-box-shadow: 0 0 3px rgba(86, 96, 117, 0.7);
  box-shadow: 0 0 3px rgba(86, 96, 117, 0.7);
  background-color: #fff;
  left: 0;
  padding: 0;
  position: absolute;
  text-shadow: none;
  top: -1000;
  z-index: 1000;
  border-radius: 2px;
  list-style: none;
}

ul.caret-dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  margin: 4px;
  clear: both;
  font-weight: normal;
  line-height: 25px;
  color: #333;
  white-space: nowrap;
}

ul.caret-dropdown-menu > li > a:hover {
  text-decoration: none;
  color: rgb(103, 106, 108);
  background-color: #f5f5f5;
}

.divider {
  padding: 2px 10px;
  background-color: #eee;
  font-size: 13px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.block-select-btn {
  display: flex;
  border-radius: 5px;
  margin: 10px 0;
  padding: 15px 20px;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  white-space: initial;
}
</style>
